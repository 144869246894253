var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results back:lightgrey" }, [
    _c(
      "div",
      { staticClass: "results__container results__container--large" },
      [
        _c(
          "h4",
          {
            staticClass: "results__title section__title section__title--medium"
          },
          [
            _c("span", [_vm._v("Alles in")]),
            _vm._v(" '" + _vm._s(_vm.$root.title) + "'")
          ]
        ),
        _vm._v(" "),
        _vm.resultAmount
          ? _c("span", { staticClass: "results__amount" }, [
              _vm._v("(" + _vm._s(_vm.resultAmount) + ")")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "results__tools" }, [
          _c(
            "div",
            { staticClass: "results__pick" },
            [
              _c("Dropdown", {
                ref: "filter-category",
                attrs: { filter: _vm.filter.tags, options: _vm.mapping.tags },
                on: { filtered: _vm.onFiltered }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "results__pick" },
            [
              _c("Dropdown", {
                ref: "filter-type",
                attrs: {
                  filter: _vm.filter.levels,
                  options: _vm.mapping.levels
                },
                on: { filtered: _vm.onFiltered }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "results__search" },
            [
              _c("Search", {
                ref: "search",
                attrs: { placeholder: _vm.placeholder },
                on: { searched: _vm.onSearch }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "results__section" },
          [
            _vm._l(_vm.resultSliced, function(result) {
              return _c("Row", {
                ref: "results",
                refInFor: true,
                attrs: { data: result }
              })
            }),
            _vm._v(" "),
            !_vm.resultSliced.length
              ? _c("div", { staticClass: "results__empty" }, [
                  _c("h4", { staticClass: "results__title" }, [
                    _vm._v("Geen resultaten")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "results__desc" }, [
                    _vm._v(
                      "Helaas, er zijn geen resultaten gevonden voor deze zoekopdracht."
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("Pagination", {
          ref: "pagination",
          attrs: { results: _vm.resultAmount, amount: 25 },
          on: { paginated: _vm.onPaginate }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }