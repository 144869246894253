import axios from 'axios';
import auth from '@js/utilities/auth';

/** ----------------------------------------
    Like
 ---------------------------------------- */

export class Like {

    constructor(el) {
        this.el = el;

        this.endpoint = '/api/like';
        this.counter = this.el.querySelector('span');
        
        this.init();
    }

    init() {
        this.el.addEventListener('click', (event) => {
            event.preventDefault();

            let id = this.el.getAttribute('data-id');
            let type = this.el.getAttribute('data-type');
            let fetch = auth(this.endpoint);

            axios.put(this.endpoint, { type: type, id: id }, fetch.headers)
                .then(response => {
                    this.counter.innerHTML = response.data.like_count;

                    let liked = response.data.status;

                    if (liked == 'liked') {
                        this.el.classList.add('is-liked');
                    } else {
                        this.el.classList.remove('is-liked');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }, false);
    }
}