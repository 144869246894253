/** ----------------------------------------
    File Input
 ---------------------------------------- */

import { Toast } from "./Toast";

export default class FileInput {

    constructor(element) {
        this.elem = element;
        this.inputEl = element.querySelector('input[type="file"]');
        this.label = this.inputEl.nextElementSibling;
        this.label.originalText = this.label.innerHTML;
        this.uploadedFileElems = element.querySelectorAll('.js-file-uploaded-file');
        this.expireEl = element.querySelector('.js-file-expire input');
        this.multiple = this.inputEl.multiple;
        this.required = this.inputEl.required;
        this.hasFilesClass = 'has-files';
        this.hasFile = this.elem.classList.contains(this.hasFilesClass);
        this.size = 0;

        if (this.required && this.hasFile) {
            this.inputEl.required = false;

            if (this.expireEl)
                this.expireEl.required = false;
        }

        this.init();
    }

    setFileEvent() {
        this.inputEl.addEventListener('change', (e) => {
            if (!e.target.files) return;

            const files = e.target.files;
            const formattedSize = this.getFormattedFilesSize(files);

            if (this.multiple && files.length > 1) {
                this.label.innerHTML = `Upload ${ files.length } documenten (${ formattedSize })`;
            } else if (files.length > 0) {
                this.label.innerHTML = `Upload ${ files[0].name } (${ formattedSize })`;
            } else {
                this.label.innerHTML = this.label.originalText;
            }
        });
    }

    setRemoveEvent() {
        for (const uploadedFile of this.uploadedFileElems) {
            const removeEl = uploadedFile.querySelector('input[type="checkbox"]');

            removeEl.addEventListener('change', () => {
                uploadedFile.style.display = 'none';

                const activeUploadedFiles = Array.from(this.uploadedFileElems).filter(element => element.style.display !== 'none');
                
                if (!activeUploadedFiles.length) {
                    this.elem.classList.remove(this.hasFilesClass);
                    this.inputEl.required = this.required;

                    if (this.expireEl)
                        this.expireEl.required = this.required;
                }
            });
        }
    }

    getFormattedFilesSize(files) {
        this.size = 0;
        Array.from(files).forEach(file => this.size += file.size);
        return this.getFormattedBytes(this.size);
    }

    getFormattedBytes(bytes, decimals = 1) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    setFutureDateValidationEvent() {
        if(!this.expireEl)
            return;

        if(this.expireEl.dataset.futureDates) {
            this.expireEl.addEventListener('focusout', () => {
                this.expireEl.removeAttribute("style");

                let inputDate = new Date(this.expireEl.value);

                if(inputDate instanceof Date || !isNaN(inputDate)) {
                    let now = new Date();

                    if(inputDate >= now) {
                        // everything is fine
                        return;
                    }
                }

                // add a red border to indicate the error.
                this.expireEl.style.borderColor = "#E42313";

                let toaster = new Toast();
                toaster.setToast('De \'geldig tot\' datum van een document kan niet in het verleden liggen.');
            })
        }
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.setFileEvent();
        this.setRemoveEvent();
        this.setFutureDateValidationEvent();
    }
}
