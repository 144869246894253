var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "40px",
        height: "40px",
        viewBox: "0 0 13 8",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M3.20502525,8.70502525 C2.93165825,8.97839226 2.93165825,9.42160774 3.20502525,9.69497475 C3.47839226,9.96834175 3.92160774,9.96834175 4.19497475,9.69497475 L9.44497475,4.44497475 C9.71834175,4.17160774 9.71834175,3.72839226 9.44497475,3.45502525 L4.19497475,-1.79497475 C3.92160774,-2.06834175 3.47839226,-2.06834175 3.20502525,-1.79497475 C2.93165825,-1.52160774 2.93165825,-1.07839226 3.20502525,-0.805025253 L7.96005051,3.95 L3.20502525,8.70502525 Z",
              id: "Path-3",
              fill: "#181716",
              "fill-rule": "nonzero",
              transform:
                "translate(6.325000, 3.950000) rotate(90.000000) translate(-6.325000, -3.950000) "
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }