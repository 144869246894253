/** ----------------------------------------
     Imports
 ---------------------------------------- */

import { throttle } from 'lodash';

/** ----------------------------------------
    Sticky Navigation
 ---------------------------------------- */

/**
 * This class will set a sticky navigation
 * bar once the user scrolls upwards.
 */

export class StickyNavigation {

    /**
     * @nav             navigation bar
     * @prevScroll      previous scroll position
     * @scroll          current scroll position
     * @ticking         scrolling state
     * @init            init class
     */

    constructor() {
        this.nav = document.querySelector('nav');
        this.share = document.querySelector('.share--sticky');
        this.header = document.querySelector('header');
        this.prevScroll = 0;
        this.scroll = 0;
        this.init();
    }

    /** ----------------------------------------
        Register Scroll
     ---------------------------------------- */

    /**
     * Register the current scroll point
     */

    _registerScroll() {
        this.scroll = window.scrollY;
        this._requestTick();
    }

    /** ----------------------------------------
        Request Ticking
     ---------------------------------------- */

    _requestTick() {
        if(this.nav.classList.contains('is-active'))
            return;

        if(!this.ticking) {
            window.requestAnimationFrame(this._updateState.bind(this));
        }

        this.ticking = true;
    }

    /** ----------------------------------------
        Set Navigation
     ---------------------------------------- */

    /**
     * Looks at scroll direction to see if
     * navigation should be visible or not.
     */

    _updateState() {
        const navHeight = this.nav.offsetHeight;
        const headerHeight = 250;

        if (this.scroll >= this.prevScroll && this.scroll > (navHeight + headerHeight) && this.scroll !== this.prevScroll && ((this.scroll - this.prevScroll) > -100)) {
            this.nav.classList.add('is-hidden');
            this.nav.classList.remove('is-visible');

            if(this.share) {
                this.share.classList.add('is-init');
                this.share.classList.remove('is-moved');
            }

            this.prevScroll = this.scroll;
        } else {
            if (this.scroll !== this.prevScroll) {
                this.nav.classList.remove('is-hidden');
                this.nav.classList.add('is-visible');

                if(this.share) {
                    this.share.classList.add('is-moved');
                    this.share.classList.remove('is-init');
                }

                this.prevScroll = this.scroll;
            }
        }

        this.ticking = false;
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        window.addEventListener('scroll', _.throttle(
            this._registerScroll.bind(this), 250)
        );
    }
}