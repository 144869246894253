import anime from 'animejs';

/** ----------------------------------------
    Toast
 ---------------------------------------- */

/**
 * The toast sets a notification to the DOM
 * to notify the user about a certain event
 * or action that has been triggered.
 */

export class Toast {

    /** ----------------------------------------
        Template
     ---------------------------------------- */

    toastTemplate(message) {
        const toast = document.createElement('div');
        const toastHolder = document.createElement('div');

        toast.classList.add('toast');
        toastHolder.classList.add('toast__holder');
        toastHolder.innerHTML = message;

        toast.appendChild(toastHolder);

        document.body.appendChild(toast);

        this.animateToast(toast);
    }

    /** ----------------------------------------
        Animate
     ---------------------------------------- */

    animateToast(toast) {
        toast.style.height = 'auto';

        anime({
            targets: toast,
            height: 0,
            easing: 'easeOutQuad',
            delay: 20000,
            duration: 250,
            complete: () => {
                toast.remove();
            }
        });
    }

    /** ----------------------------------------
        Set Toast
     ---------------------------------------- */

    setToast(message) {
        this.toastTemplate(message);
    }
}