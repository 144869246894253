<template>
    <div class="threads">

        <!-- INFO -->
        <h4 class="threads__title section__title section__title--medium">{{ title }}</h4>
        <span class="threads__amount" v-if="resultAmount">{{ resultAmount }}</span>

        <!-- FILTERS -->
        <div class="threads__tools">
            <div class="threads__search">
                <Search @searched="onSearch" :placeholder="placeholder" ref="search"></Search>
            </div>
            <div class="threads__pick">
                <Dropdown :filter="filter.themes" :options="mapping.themes" @filtered="onFiltered" ref="filter-category"></Dropdown>
            </div>
        </div>

        <!-- RESULTS -->
        <div class="threads__section">
            <Thread v-for="result in resultSliced" :data="result" ref="results"></Thread>
        </div>

        <!-- PAGINATION -->
        <Pagination :results="resultAmount" :amount="25" :mod="pagination" @paginated="onPaginate" ref="pagination"></Pagination>

    </div>
</template>

<script>

    /* ----------------------------------------
        Node Modules
    ---------------------------------------- */

    import axios from 'axios';

    /* ----------------------------------------
        Utilities
    ---------------------------------------- */

    import auth from '@js/utilities/auth';

    /* ----------------------------------------
        Mockup
    ---------------------------------------- */

    import { threadExample, threadMapping } from './mockup';

    /* ----------------------------------------
        Modules
    ---------------------------------------- */

    import Dropdown from './Dropdown.vue';
    import Pagination from './Pagination.vue';
    import Search from './Search.vue';
    import Thread from './Thread.vue';

    /* ----------------------------------------
        Component
    ---------------------------------------- */

    export default {

        name: 'Threads',

        /* ----------------------------------------
            Data
        ---------------------------------------- */

        /**
         * The title adds the heading of the Results
         * component. The filter object contains the
         * information being displayed in the picklist.
         * The mapping and items are responsible for
         * showing the actual results within the
         * container. Paginate, search and selected
         * store the reactive data of given selections
         * made by the user. These values are used
         * within several methods and computed properties
         * to display the results accordingly.
         */

        data() {
            return {
                title: 'Onderwerpen',
                placeholder: 'Zoeken op onderwerp...',
                pagination: 'grey',
                filter: {
                    themes: {
                        name: 'Thema\'s',
                        label: 'themes'
                    }
                },
                mapping: threadMapping,
                items: [],
                paginate: {},
                search: '',
                selected: [],
            };
        },

        computed: {

            /* ----------------------------------------
                Amount of Results
            ---------------------------------------- */

            /**
             * This function will retrieve the amount
             * of filtered (or searched) items from the
             * resultFiltered array.
             */

            resultAmount() {
                return this.resultFiltered.length;
            },

            /* ----------------------------------------
                Search Results
            ---------------------------------------- */

            /**
             * This function will look for matches based
             * on a given string (or query) that the user
             * has provided from the search input. This
             * one is pretty easy to get your head around.
             */

            resultSearch() {
                const query = this.search.toLowerCase();

                return this.items.filter(item => {
                    return item.title.toLowerCase().includes(query);
                })
            },

            /* ----------------------------------------
                Filtered Results
            ---------------------------------------- */

            /**
             * If a filter is present this function
             * will filter through the selected values
             * and resolve an array of results. If
             * multiple filters are selected an operator
             * will manipulate the array retrieving items
             * with the most hits.
             */

            resultFiltered() {
                let results = [];
                const selectedLength = this.selected.length;
                const multiple = selectedLength > 1;

                if(selectedLength === 0)
                    return this.resultSearch;

                this.resultSearch.map(item => {
                    this.selected.map(filter => {
                        if (item[filter.type].includes(filter.value))
                            results.push(item);
                    });
                });

                return multiple ? this.filterDouble(results) : results;
            },

            /* ----------------------------------------
                Slided Results
            ---------------------------------------- */

            /**
             * This function will be responsible for slicing
             * the results according to the offset and selected
             * pagination tab.
             */

            resultSliced() {
                return this.resultFiltered.slice(this.paginate.start, this.paginate.end)
            }

        },

        /* ----------------------------------------
           Components
        ---------------------------------------- */

        /**
         * Nothing you don't know already ;)
         */

        components: {
            Dropdown,
            Pagination,
            Thread,
            Search
        },

        methods: {

            /* ----------------------------------------
                Filter Event
            ---------------------------------------- */

            /**
             * Once a filter is selected the selected
             * filter is being stored in the selected
             * data array. If a selected filter type is
             * already present in the array the function
             * it will replace it. This means only one type
             * of filter can be present in the array at
             * once. If the filter value equals 0 the
             * function will remove the selected type
             * from the array. This will prevent a filter
             * taking place on the selected type of filter
             * restoring it as "all".
             *
             * First part of this function removes the
             * filter type from the selected array.
             *
             * Second part, if the filter value is not
             * zero, the filter will be pushed to the
             * selected array.
             *
             * Once function has been executed pagination
             * will be refreshed.
             */

            onFiltered(filter) {
                this.selected.map(select => {
                    if(select.type === filter.type) {
                        const index = this.selected.indexOf(select);
                        if (index > -1) this.selected.splice(index, 1);
                    }
                });

                if(parseInt(filter.value) !== 0)
                    this.selected.push(filter);

                this.resetPagination();
            },

            /* ----------------------------------------
                Filter Double
            ---------------------------------------- */

            /**
             * This function will select the results
             * that have the most matches from multiple
             * filters. This is a helper utility that
             * returns all doubles in a new array.
             */

            filterDouble(array) {
                return array.filter(function (value, index, self) {
                    return self.indexOf(value) !== index;
                });
            },

            /* ----------------------------------------
                Search Event
            ---------------------------------------- */

            /**
             * This event is being triggered once a
             * user starts typing, the event will be
             * debounced from the Search component.
             * After search is triggered the pagination
             * will be reset.
             */

            onSearch(search) {
                this.search = search.value;
                this.resetPagination();
            },

            /* ----------------------------------------
                Pagination Event
            ---------------------------------------- */

            /**
             * This method is called once the user
             * paginates in between tabs. It will set
             * the pagination values as configured
             * within the emitter.
             */

            onPaginate(values) {
                this.paginate = values;
            },

            /* ----------------------------------------
                Reset
            ---------------------------------------- */

            /**
             * This function will calls the reset method
             * from the pagination component. Using the
             * refs attribute in the template the component
             * can be reached.
             */

            resetPagination() {
                setTimeout(() => {
                    this.$refs['pagination'].reset();
                }, 50);
            }
        },

        /* ----------------------------------------
            Mounted
        ---------------------------------------- */

        mounted() {
            const fetch = auth('/api/threads');

            axios.get('/api/threads', fetch.headers)
                .then(response => {
                    this.items = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });

            axios.get('/api/threads/filters', fetch.headers)
                .then(response => {
                    this.mapping = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    };
</script>
