<template>
    <div class="reaction">
        <form class="form reaction__form">
            <div class="form__group">
                <textarea class="reaction__area" maxlength="2000" placeholder="Type hier je reactie..." ref="area"></textarea>
            </div>
            <button class="btn-solid--lightblue reaction__submit" type="submit" v-on:click="submit">Plaats reactie</button>
        </form>
    </div>
</template>

<script>

    /* ----------------------------------------
        Node Modules
    ---------------------------------------- */

    import axios from 'axios';

    /* ----------------------------------------
        Authenticator
    ---------------------------------------- */

    import auth from '@js/utilities/auth';

    /* ----------------------------------------
        Component
    ---------------------------------------- */

    export default {

        name: 'Reaction',

        /* ----------------------------------------
            Submit Reaction
        ---------------------------------------- */

        methods: {
            submit(e) {
                e.preventDefault();
                let value = this.$refs.area.value;

                const main = document.querySelector('.main');
                const id = main.dataset.id;
                const fetch = auth(`/api/comment/${ id }`);

                if(value.length < 3)
                    return;

                axios.post(`/api/comment/${ id }`, { comment: value }, fetch.headers)
                    .then(response => {
                        this.$emit('reacted', response.data.data);
                        this.$refs.area.value = '';
                    })
                    .catch(error => {
                        console.log('Error', error)
                    });
            }
        }

    };

</script>
