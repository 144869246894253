var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schools" },
    [
      _c(
        "h4",
        { staticClass: "schools__title section__title section__title--medium" },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm.resultAmount
        ? _c("span", { staticClass: "schools__amount" }, [
            _vm._v("(" + _vm._s(_vm.resultAmount) + ")")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "schools__tools" }, [
        _c("div", { staticClass: "schools__filters" }, [
          _c(
            "div",
            { staticClass: "schools__search" },
            [
              _c("Search", {
                ref: "search",
                attrs: { placeholder: _vm.placeholder },
                on: { searched: _vm.onSearch }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "schools__filters" }, [
          _c(
            "div",
            { staticClass: "schools__pick" },
            [
              _c("Dropdown", {
                ref: "filter-type",
                attrs: { filter: _vm.filter.types, options: _vm.mapping.types },
                on: { filtered: _vm.onFiltered }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "schools__pick" },
            [
              _c("Dropdown", {
                ref: "filter-category",
                attrs: {
                  filter: _vm.filter.countries,
                  options: _vm.mapping.countries
                },
                on: { filtered: _vm.onFiltered }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "schools__pick" },
            [
              _c("Dropdown", {
                ref: "filter-education_forms",
                attrs: {
                  filter: _vm.filter.education_forms,
                  options: _vm.mapping.education_forms
                },
                on: { filtered: _vm.onFiltered }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "schools__section" },
        [
          _vm._l(_vm.resultSliced, function(result) {
            return _c("School", {
              ref: "results",
              refInFor: true,
              attrs: { data: result }
            })
          }),
          _vm._v(" "),
          _vm.resultSliced.length <= 0 && _vm.items.length > 0
            ? [_c("h5", [_vm._v("Geen resultaten gevonden")])]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("Pagination", {
        ref: "pagination",
        attrs: { results: _vm.resultAmount, amount: 15, mod: _vm.pagination },
        on: { paginated: _vm.onPaginate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }