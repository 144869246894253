var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "7px",
        height: "12px",
        viewBox: "0 0 7 12",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M0.205025253,10.7050253 C-0.0683417511,10.9783923 -0.0683417511,11.4216077 0.205025253,11.6949747 C0.478392257,11.9683418 0.921607743,11.9683418 1.19497475,11.6949747 L6.44497475,6.44497475 C6.71834175,6.17160774 6.71834175,5.72839226 6.44497475,5.45502525 L1.19497475,0.205025253 C0.921607743,-0.0683417511 0.478392257,-0.0683417511 0.205025253,0.205025253 C-0.0683417511,0.478392257 -0.0683417511,0.921607743 0.205025253,1.19497475 L4.96005051,5.95 L0.205025253,10.7050253 Z",
              id: "Path-3",
              fill: "#181716",
              "fill-rule": "nonzero"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }