/** ----------------------------------------
    Load More
 ---------------------------------------- */

/**
 * This class will handle load more functionality
 */

export class LoadMore {

    constructor(el) {
        this.el = el;

        if (this.el === null) {
            return;
        }

        this.items = this.el.querySelectorAll(this.el.dataset.selector);
        this.moreButton = this.el.querySelector('.js-more-button');
        this.nextButton = this.el.querySelector('.js-next-button');
        this.prevButton = this.el.querySelector('.js-prev-button');
        this.itemsPerPage = this.el.dataset.items ? parseInt(this.el.dataset.items, 10) : 5;
        this.itemsPerPageMobile = this.el.dataset.items ? parseInt(this.el.dataset.itemsSmall, 10) : 3;

        this.init();
    }

    reset() {
        this.start = 0;
        this.end = this.getIncreasement();

        this.updateLoadMoreItems();
    }

    getIncreasement() {
        return this.smallView ? this.itemsPerPageMobile : this.itemsPerPage;
    }

    updateLoadMoreItems() {
        for (let i = 0; i < this.items.length; i++) {
            const element = this.items[i];
            if (i >= this.start && i < this.end) {
                element.classList.remove('hidden');
            } else {
                element.classList.add('hidden');
            }
        }

        if (this.end >= this.items.length) {
            this.moreButton.classList.add('hidden');
            this.nextButton.classList.add('disabled');
        } else {
            this.nextButton.classList.remove('disabled');
        }

        if (this.start > 0) {
            this.prevButton.classList.remove('disabled');
        } else {
            this.prevButton.classList.add('disabled');
        }
    }

    setButtonEvents() {
        this.moreButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.end+= this.getIncreasement();
            this.updateLoadMoreItems();
        });

        this.nextButton.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.nextButton.classList.contains('disabled')) {
                return;
            }

            this.start+= this.getIncreasement();
            this.end+= this.getIncreasement();
            this.updateLoadMoreItems();
        });

        this.prevButton.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.prevButton.classList.contains('disabled')) {
                return;
            }

            this.start-= this.getIncreasement();
            this.end-= this.getIncreasement();
            this.updateLoadMoreItems();
        });
    }

    setResizeEvents() {
        window.addEventListener('resize', () => {
            if (!this.smallView && window.innerWidth <= 960) {
                this.smallView = true;
                this.reset();
            }

            if (this.smallView && window.innerWidth > 960) {
                this.smallView = false;
                this.reset();
            }
        });
    }

    init() {
        this.smallView = window.innerWidth <= 960;

        if (this.items.length <= this.itemsPerPageMobile) {
            this.nextButton.classList.add('hidden');
            this.prevButton.classList.add('hidden');
        }

        this.setButtonEvents();
        this.setResizeEvents();
        this.reset();
    }
}
