<template>
    <div>

        <!-- PAGINATION -->
        <div class="pagination" v-if="render > 0 && results > offset">
            <div class="pagination__dot pagination__arrow pagination__arrow--prev" v-bind:class="[hasModifier, hasPrev]" v-on:click="setTab(index-1)">
                <span class="pagination__value"><Left></Left></span>
            </div>

            <div class="pagination__dot" v-for="index in tabs" v-on:click="setTab(index)" v-bind:class="[hasModifier, isActive(index)]">
                <span class="pagination__value">{{ index }}</span>
            </div>

            <div class="pagination__dot pagination__arrow pagination__arrow--next" v-bind:class="[hasModifier, hasNext]" v-on:click="setTab(index+1)">
                <span class="pagination__value"><Right></Right></span>
            </div>
        </div>

        <!-- DEBUG BAR -->
        <div v-if="debug">
            <span class="pagination__debug">Amount: {{ results }} | Index: {{ index }} | Render: {{ render }} | Start: {{ start }} | End: {{ end }}</span>
        </div>

    </div>
</template>

<script>

    /* ----------------------------------------
        Icons
    ---------------------------------------- */

    import Left from '@js/vue/svg/arrow-left.vue';
    import Right from '@js/vue/svg/arrow-right.vue';

    /* ----------------------------------------
        Component
    ---------------------------------------- */

    export default {

        name: 'Pagination',

        /* ----------------------------------------
            Data
        ---------------------------------------- */

        /**
         * The default index once is used to set
         * the active tab of the pagination, the
         * offset value determines the amount of
         * results rendered in every view of the
         * pagination render.
         *
         * If needed set debug to true to display
         * values stored while paginating.
         */

        data() {
            return {
                debug: false,
                index: 1,
                offset: this.amount || 10
            }
        },

        computed: {

            /* ----------------------------------------
                Tabs
            ---------------------------------------- */

            /**
             * This function will count the amount of tabs
             * to be displayed based on the offset.
             */

            tabs() {
                return Math.ceil(this.results/this.offset)
            },

            /* ----------------------------------------
                Hav Prev
            ---------------------------------------- */

            /**
             * This function will check if there is previous
             * page available on the prev arrow, if non are
             * available a class is-disabled will be set
             */

            hasPrev() {
                if(this.index === 1) return 'is-disabled';
            },

            /* ----------------------------------------
                Hav Next
            ---------------------------------------- */

            /**
             * This function will check if there is next
             * page available on the prev arrow, if non are
             * available a class is-disabled will be set
             */

            hasNext() {
                if(this.index === this.tabs) return 'is-disabled';
            },

            /* ----------------------------------------
                Has Color
            ---------------------------------------- */

            /**
             * Check if a prop with a color has been passed
             * to the component and add a modifier to the
             * pagination elements.
             */

            hasModifier() {
                if(this.mod) return `pagination__dot--${ this.mod }` ;
            },

            /* ----------------------------------------
                Start
            ---------------------------------------- */

            /**
             * Will return a number of the first index
             * to be displayed from a array of items.
             */

            start() {
                return this.offset * (this.index - 1);
            },

            /* ----------------------------------------
                End
            ---------------------------------------- */

            /**
             * Will return a number of the end index
             * to be displayed from a array of items.
             *
             * Function will check left over when the
             * last page is reached.
             */

            end() {
                if(this.index === this.tabs) {
                    const modulo = this.results % this.offset;
                    const leftOver = modulo === 0 ? this.offset : modulo;
                    return this.start + leftOver;
                }

                return this.start + this.offset;
            },

            /* ----------------------------------------
                Render
            ---------------------------------------- */

            /**
             * The amount of items to be rendered as a
             * result, based on start and ending index.
             */

            render() {
                return this.end - this.start;
            }

        },

        props: [
            'amount',
            'mod',
            'results'
        ],

        /* ----------------------------------------
            Components
        ---------------------------------------- */

        /**
         * These components will import the SVG icons
         * used for the previous and next arrow.
         */

        components: {
            Left,
            Right
        },

        methods: {

            /* ----------------------------------------
                Set Tab
            ---------------------------------------- */

            /**
             * This function will be executed once a
             * pagination dot has been clicked. It will
             * dispatch an event and set the new index.
             */

            setTab(index) {
                if(this.index === index)
                    return;

                this.index = index;
                this.paginate();
            },

            /* ----------------------------------------
                Set Active
            ---------------------------------------- */

            /**
             * This function will set the current item
             * to active if clicked.
             */

            isActive(index) {
                if(this.index === index) return 'is-active';
            },

            /* ----------------------------------------
                Paginate
            ---------------------------------------- */

            /**
             * This event will be dispatch all the stored
             * variables and configurations set to this
             * component.
             */

            paginate() {
                this.$emit('paginated', {
                    index: this.index,
                    total: this.render,
                    results: this.end - this.start,
                    start: this.start,
                    end: this.end
                });
            },

            /* ----------------------------------------
                Reset
            ---------------------------------------- */

            /**
             * Will reset the pagination to the default
             * index of 1 and dispatch an event.
             */

            reset() {
                this.index = 1;
                this.paginate();
            },

        },

        /* ----------------------------------------
            Mounted
        ---------------------------------------- */

        /**
         * Once the component is initialized the
         * pagination event will be triggered
         * dispatching the default configuration
         * with an index of 1.
         */

        mounted() {
            this.paginate();
        }

    };
</script>