/* ----------------------------------------
    Component
---------------------------------------- */

export default class Tooltip {

    constructor(config) {
        this.config = config;
        this.el = config.el;
        this.targetClass = this.config.target.substr(1);
        this.triggerEl = this.el.querySelector(this.config.trigger);
        this.targetEl = this.el.querySelector(this.config.target);
        this.contentEl = this.el.querySelector(this.config.content);
        this.activeClass = (this.config.activeClass) ? this.config.activeClass : 'is-active';
        this.active = false;
        this.whitespace = 'nowrap';
        this.fixWidth = false;
        this.position = 'left';
        this.proxyPosition = this.position;

        this.init();
    }

    setEventsListeners() {
        this.triggerEl.addEventListener('mouseenter', (e) => {
            e.stopPropagation();
            this.active = true;
            this.onActiveChange();
        });

        this.triggerEl.addEventListener('mouseleave', (e) => {
            e.stopPropagation();
            this.active = false;
            this.onActiveChange();
        });
    }

    onActiveChange() {
        if (!this.active) {
            this.reset();
        } else {
            this.updateWhitespace();
            this.updateProxyPosition();
        }

        this.updateClass();
        this.updateTargetClass();
    }

    updateClass() {
        if (this.active) {
            this.el.classList.add(this.activeClass);
        } else {
            this.el.classList.remove(this.activeClass);
        }
    }

    updateTargetClass() {
        ['wrap', 'nowrap', 'left', 'right', 'fix-width'].forEach(str => {
            this.targetEl.classList.remove(`${ this.targetClass }--${ str }`);
        });
        this.targetEl.classList.add(`${ this.targetClass }--${ this.proxyPosition }`);
        this.targetEl.classList.add(`${ this.targetClass }--${ this.whitespace }`);

        if (this.fixWidth) {
            this.targetEl.classList.add(`${ this.targetClass }--fix-width`);
        }
    }

    reset() {
        this.whitespace = 'nowrap';
        this.proxyPosition = this.position;

        if (this.fixWidth) {
            this.targetEl.style.left = '';
            this.targetEl.style.maxWidth = '';
        }

        this.fixWidth = false;
    }

    updateWhitespace() {
        const targetRect = this.targetEl.getBoundingClientRect();
        const contentRect = this.contentEl.getBoundingClientRect();

        if (contentRect.width > targetRect.width)
            this.whitespace = 'wrap';
    }

    updateProxyPosition() {
        if (!this.active) return;

        const box = this.targetEl;
        const boxRect = box.getBoundingClientRect();

        if (boxRect.left + boxRect.width > window.innerWidth) {
            if (boxRect.left > window.innerWidth / 2 && boxRect.width < window.innerWidth) {
                this.proxyPosition = 'right';
            } else {
                this.targetEl.style.left = `calc(5vw + ${ 0 - boxRect.left }px)`;
                this.targetEl.style.maxWidth = '90vw';
                this.fixWidth = true;
            }
        }

    }

    init() {
        this.setEventsListeners();
        this.updateTargetClass();
    }
}