/** ----------------------------------------
    Imports
 ---------------------------------------- */

import anime from 'animejs';
import { isDescendant } from '../helpers/Helpers';

/** ----------------------------------------
    Select
 ---------------------------------------- */

export class Select {
    
    /**
     * Constructor
     * 
     * @param  {object} element // dropdown element
     * @param  {object} host // contains data about the host
     * @param  {object} settings // contains settings
     * @param  {bool} initializeOnConstruct // init on construct
     */
    constructor(element, host, settings = {}, initializeOnConstruct = true) {
        this.element = element;

        this.defaultSettings = {
            triggerSelector: '.select__trigger',
            triggerTextSelector: '.select__trigger-text',
            triggerAmountSelector: '.select__trigger-amount',
            dropdownClass: 'select__dropdown',
            dropdownContentClass: 'select__dropdown-content',
            dropdownOptionClass: 'select__dropdown-option',
            activeClass: 'is-active',
            valueClass: 'has-value',
            animatingClass: 'is-animating',
            revertDirectionClass: 'revert-direction',
            onInitialize: () => {},
            onOpen: () => {},
            onClose: () => {},
            onChange: () => {},
        };

        this.settings = { ...this.defaultSettings, ...settings };
        this.host = host;

        this.handleDocumentClick = this.handleDocumentClick.bind(this);

        this.select = this.element.querySelector('select');
        this.options = Array.from(this.select.querySelectorAll('option'));
        this.trigger = this.element.querySelector(this.settings.triggerSelector);
        this.triggerText = this.trigger.querySelector(this.settings.triggerTextSelector);
        this.triggerAmount = this.trigger.querySelector(this.settings.triggerAmountSelector);

        this.useDropdown = !this.host.isMobile();
        this.multiple = this.select.multiple;
        this.dropdown = null;
        this.dropdownContent = null;
        this.dropdownOptions = [];
        this.dropdownOptionsMap = new Map()
        this.searchTime = (new Date()).getTime();
        this.searchTimeout = 2000;
        this.searchString = '';
        this.active = false;
        this.keydown = false;

        if (initializeOnConstruct)
            this.init();
    }

    /**
     * Initialize
     */
    init() {
        if (this.useDropdown) {
            this.createDropdownNode();
            this.createDropdownContentNode();
            this.createDropdownOptionNodes();
            this.updateSelectStyling();
            this.addDropdownOptionEvents();
            this.addTriggerEvent();
            this.addDropdownEvent();
        }

        this.addSelectEvent();
        this.addResetEvent();
        this.updateTrigger();
        this.createSelectObserver();

        this.settings.onInitialize();
    }

    /** ----------------------------------------
        Getters and setters
    ---------------------------------------- */

    /*
     * Get value. Returns array on multiple select and a string on single select
     */
    /**
     * @returns Array<string>|string
     */
    get value() {
        const values = this.getSelectedOptions().map(element => element.value);

        return (this.multiple) ? values : (values.length === 1 ) ? values[0] : '';
    }

    /**
     * Set value
     * @param  {Array<string>|string} values
     */
    set value(values) {
        if (typeof values === 'string')
            values = [values];

        this.getOptionsWithValue().forEach(option => {
            option.selected = values.includes(option.value);
        });
        
        this.select.dispatchEvent(new Event('change'));
    }

    /*
     * Set on intialized function
     * @param  {Function} fn
     */
    set onInitialize(fn) {
        this.settings.onInitialize = fn;
    }

    /*
     * Set on change function
     * @param  {Function} fn
     */
    set onChange(fn) {
        this.settings.onChange = fn;
    }

    /*
     * Set on open function
     * @param  {Function} fn
     */
    set onOpen(fn) {
        this.settings.onOpen = fn;
    }

    /**
     * Set on close function
     * @param  {Function} fn
     */
    set onClose(fn) {
        this.settings.onClose = fn;
    }

    /** ----------------------------------------
        Open/close functions
    ---------------------------------------- */

    /*
     * Open dropdown
     * @returns {void}
     */
    open() {
        this.active = true;
        this.element.classList.add(this.settings.activeClass);
        this.focusDropdownOptionClosestToIndex(0);
        this.addDocumentEvent();
        this.setDropdownDirection();
        this.settings.onOpen();
        this.animateOpen();
    }

    

    /*
     * Close dropdown and remove document event
     * @returns {void}
     */
    close() {
        this.active = false;
        this.element.classList.remove(this.settings.activeClass);
        this.removeDocumentEvent();
        this.settings.onClose();
        this.animateClose();
    }

    /**
     * Animate dropdown open
     * @returns {void}
     */
    animateOpen() {
        let height = this.dropdownContent.offsetHeight;
        height = height > 320 ? 320 : height;

        this.animate(height, 200, () => {
            this.dropdown.style.height = 'auto';
        });
    }

    /**
     * Animate dropdown open
     * @returns {void}
     */
    animateClose() {
        this.animate(0, 175);
    }

    /**
     * Animate dropdown
     * @param  {number} height
     * @param  {number} speed
     * @param  {null|Function} callback
     */
    animate(height, speed, callback = null) {
        this.element.classList.add(this.settings.animatingClass);

        anime({
            targets: this.dropdown,
            height: height,
            duration: speed,
            easing: 'easeOutQuad',
            complete: () => {
                this.element.classList.remove(this.settings.animatingClass);

                if (callback)
                    callback();
            }
        });
    }

    /** ----------------------------------------
        DOM Manipulation
    ---------------------------------------- */

    /*
     * Create dropdown node
     * @returns {void}
     */
    createDropdownNode() {
        this.dropdown = document.createElement('div');

        if (this.settings.dropdownClass)
            this.dropdown.classList.add(this.settings.dropdownClass);

        this.element.appendChild(this.dropdown);
    }

    /*
     * Create dropdown content node
     * @returns {void}
     */
    createDropdownContentNode() {
        this.dropdownContent = document.createElement('div');

        if (this.settings.dropdownContentClass)
            this.dropdownContent.classList.add(this.settings.dropdownContentClass);

        this.dropdown.appendChild(this.dropdownContent);
    }

    /*
     * Create dropdown option nodes based on all options
     * @returns {void}
     */
    createDropdownOptionNodes() {
        this.dropdownOptions = new Array();

        this.getOptionsWithValue().forEach(option => {
            this.createDropdownOptionNode(option);
        });
    }

    /**
     * Create dropdown option node based on given option
     * @param  {HTMLOptionElement} option
     * @returns null|HTMLAnchorElement
     */
    createDropdownOptionNode(option) {
        const dropdownOption = document.createElement('a');

        dropdownOption.tabIndex = 0;
        dropdownOption.href = 'javascript:;';

        if (this.settings.dropdownOptionClass)
            dropdownOption.className = this.settings.dropdownOptionClass;
        
        this.dropdownOptionsMap.set(dropdownOption, option);
        this.dropdownContent.appendChild(dropdownOption);
        this.dropdownOptions.push(dropdownOption);

        this.updateDropdownOption(dropdownOption);

        return dropdownOption;
    }

    /**
     * Remove dropdown option node based on given option
     * @param  {HTMLOptionElement} option
     * @returns {void}
     */
    removeDropdownOptionNode(option) {
        const dropdownOption = this.getDropdownOptionFromMap(option);

        this.dropdownOptionsMap.delete(dropdownOption);

        this.dropdownOptions = this.dropdownOptions.filter(el => el !== dropdownOption);

        dropdownOption.remove();
    }

    /*
     * Update all dropdown option nodes
     * @returns {void}
     */
    updateDropdownOptions() {
        this.dropdownOptions.forEach(dropdownOption => {
            this.updateDropdownOption(dropdownOption);
        });
    }

    /**
     * Update given dropdown option node
     * @param  {HTMLAnchorElement} dropdownOption
     * @returns {void}
     */
    updateDropdownOption(dropdownOption) {
        const option = this.dropdownOptionsMap.get(dropdownOption);

        // Update data attribute
        const propsToDataset = ['disabled', 'selected', 'value'];
        propsToDataset.forEach(prop => {
            if (typeof option[prop] === 'boolean') {
                if (option[prop])
                    dropdownOption.dataset[prop] = '';
                else
                    delete dropdownOption.dataset[prop];
            } else {
                if (dropdownOption.dataset[prop] !== option[prop])
                    dropdownOption.dataset[prop] = option[prop];
            }
        });

        dropdownOption.tabIndex = (option.disabled) ? -1 : 0;

        // Update text
        if (dropdownOption.textContent !== option.textContent)
            dropdownOption.textContent = option.textContent;
    }

    /**
     * Update trigger text
     * @returns {void}
     */
    updateTrigger() {
        const options = this.getSelectedOptions();

        const enabledOptions = options.filter(option => {
            return !option.disabled;
        });

        if (enabledOptions.length) {
            this.element.classList.add(this.settings.valueClass)
        } else {
            this.element.classList.remove(this.settings.valueClass)
        }

        if (this.multiple) {
            if (this.triggerAmount)
                this.triggerAmount.innerText = options.length;
        } else if (options.length) {
            this.triggerText.innerText = options[0].innerHTML;
        }
    }

    /*
     * UpdateSelectStyling
     * @returns {void}
     */
    updateSelectStyling() {
        this.select.style.zIndex = '0';
        this.select.tabIndex = -1;
    }

    /** ----------------------------------------
        Event listeners
    ---------------------------------------- */

    /*
     * Add select event
     * @returns {void}
     */
    addSelectEvent() {
        this.select.addEventListener('change', () => {
            this.updateTrigger();

            if (this.useDropdown)
                this.updateDropdownOptions();

            this.settings.onChange();
        });
    }

    /**
     * Add reset event
     * @returns {void}
     */
    addResetEvent() {
        // - Unselect options
        // - Trigger select change event
        this.element.addEventListener('reset', () => {
            this.options.forEach(option => {
                option.selected = !(option.value);
            });

            this.select.dispatchEvent(new Event('change'));
        });
    }

    /**
     * Add dropdown options event
     * @returns {void}
     */
    addDropdownOptionEvents() {
        this.dropdownOptions.forEach(dropdownOption => {
            this.addDropdownOptionEvent(dropdownOption);
        });
    }

    /**
     * Add dropdown options event
     * @param  {HTMLAnchorElement} dropdownOption
     * @returns {void}
     */
    addDropdownOptionEvent(dropdownOption) {
        // - Select dropdown option on click
        // - Ignore for disabled option
        // - Trigger change event
        // - Close dropdown afterwards for single select
        dropdownOption.addEventListener('click', (e) => {
            e.preventDefault();

            const option = this.dropdownOptionsMap.get(dropdownOption);

            if (option.disabled)
                return;

            if (this.multiple) {
                option.selected = !option.selected;

                this.select.dispatchEvent(new Event('change'));
            }

            if (!this.multiple && !option.selected) {
                option.selected = true;

                this.select.dispatchEvent(new Event('change'));
            }

            if (!this.multiple)
                this.close();
        });

        // - Focus dropdown option on mouseover
        // - Ignore for disabled option or when typing
        dropdownOption.addEventListener('mouseover', () => {
            if (this.keydown)
                return;

            const option = this.dropdownOptionsMap.get(dropdownOption);

            if (option.disabled) 
                return;

            this.focusDropdownOptionByIndex(this.dropdownOptions.indexOf(dropdownOption));
        });

        // - Prevent dropdown option focus on disabled option
        dropdownOption.addEventListener('mousedown', () => {
            const option = this.dropdownOptionsMap.get(dropdownOption);

            if (option.disabled) 
                event.preventDefault();
        });
    }

    /**
     * Add trigger event
     * @returns {void}
     */
    addTriggerEvent() {
        // - Open/close dropdown
        // - Ignore when select is disabled
        this.trigger.addEventListener('click', ev => {
            ev.preventDefault();

            if (this.disabled) 
                return;

            (this.active) ? this.close() : this.open();
        });
    }

    /**
     * Add dropdown events
     * @returns {void}
     */
    addDropdownEvent() {
        // - Focus next dropdown option on key down
        // - Focus previous dropdown option on key up
        // - Focus dropdown option that matched search string when typing
        this.dropdown.addEventListener('keydown', ev => {
            this.keydown = true;

            if (ev.keyCode === 38) { // up
                ev.preventDefault();
                this.focusDropdownOptionByDirection(-1);
                return undefined;
            }

            if (ev.keyCode === 40) { // down
                ev.preventDefault();
                this.focusDropdownOptionByDirection(1);
                return undefined;
            }


            if (ev.keyCode === 8) { // backspace
                ev.preventDefault();
                this.searchString = this.searchString.slice(0, -1);
                this.focusDropdownOptionBySearchedString('');
                return undefined;
            }

            const char = String.fromCharCode(ev.keyCode);

            if (
                (char && char.match(/^[a-z0-9 ]+$/i)) &&
                !(ev.ctrlKey || ev.altKey || ev.metaKey)
            ) {
                ev.preventDefault();
                this.focusDropdownOptionBySearchedString(char);
            }
        });

        this.dropdown.addEventListener('keyup', ev => {
            this.keydown = false;
        });
    }

    /**
     * Add document event
     * @returns {void}
     */
    addDocumentEvent() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    /**
     * Remove document event
     * @returns {void}
     */
    removeDocumentEvent() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    /**
     * Handle document click
     * @param  {Event} ev
     * @returns {void}
     */
    handleDocumentClick(ev) {
        const target = ev.target;

        if (target !== this.element && !this.isDescendant(this.element, target))
            this.close();
    }

    /** ----------------------------------------
        Dropdown option focus
    ---------------------------------------- */

    /**
     * Focus dropdown option by index
     * Ignore disabled option
     * @param  {number} index
     * @returns {void}
     */
    focusDropdownOptionByIndex(index) {
        window.requestAnimationFrame(() => {
            const option = this.dropdownOptionsMap.get(this.dropdownOptions[index]);

            if (!option.disabled)
                this.dropdownOptions[index].focus();
        });
    }

    /**
     * Focus next dropdown option based on current focused option and given direction
     * Skip disabled option
     * @param  {number} focusDirection
     * @returns {void}
     */
    focusDropdownOptionByDirection(focusDirection) {
        this.dropdownOptions.forEach((dropdownOption, index) => {
            if (document.activeElement === dropdownOption) {
                let nextIndex = index + focusDirection;

                while (typeof this.dropdownOptions[nextIndex] !== undefined) {
                    const nextOption = this.dropdownOptionsMap.get(this.dropdownOptions[nextIndex]);
                    
                    if (nextOption.disabled) {
                        nextIndex+= focusDirection;
                    } else {
                        this.focusDropdownOptionByIndex(nextIndex);
                        break;
                    }
                }
            }
        });
    }

    /**
     * Focus dropdown option closest to index. First checks current index, 
     * then next index, then prev index, the 2nd next index, then 2nd prev index etc.
     * Skips disabled option.
     * @param  {number} index
     * @returns {void}
     */
    focusDropdownOptionClosestToIndex(index) {
        for (let i = 0; i <= this.dropdownOptions.length; i++) {
            let breakLoop = false;

            for (let j = 1; j >= -1; j-= 2) {
                const x = index + (i * j);

                if (!this.dropdownOptions[x])
                    continue;
                
                const option = this.dropdownOptionsMap.get(this.dropdownOptions[x]);

                if (!option.disabled) {
                    this.focusDropdownOptionByIndex(x);
                    breakLoop = true;
                    break;
                }

                if (x === 0)
                    break;
            }

            if (breakLoop)
                break;
        }
    }

    /**
     * Focus dropdown option based on search
     * If all match (empty string): Focus closest focusable to index 0
     * Else if matches found: Focus first match
     * Else if searching 1 char: Focus option that is alphabeticly closest to the char
     * Else: Don't change focus
     * @param  {string} char
     * @returns {void}
     */
    focusDropdownOptionBySearchedString(char) {
        const time = (new Date()).getTime();

        if (time > this.searchTime + this.searchTimeout)
            this.searchString = '';

        this.searchTime = time;
        this.searchString+= char.toLowerCase();

        const matches = this.dropdownOptions.filter(option => option.innerHTML.toLowerCase().startsWith(this.searchString));

        if (matches.length === this.dropdownOptions.length) {
            this.focusDropdownOptionClosestToIndex(0);
        } else if (matches.length) {
            this.focusDropdownOptionClosestToIndex(this.dropdownOptions.indexOf(matches[0]));
        } else if (this.searchString.length === 1) {
            this.focusDropdownOptionByFirstLetter(this.searchString);
            
        }
    }

    /**
     * Focus dropdown option where first letter is alphabetically closest to given char
     * @param  {string} char
     * @returns {void}
     */
    focusDropdownOptionByFirstLetter(char) {
        const searchLetterIndex = parseInt(char, 36) - 9;
        let lowestDiff = 26;
        let closestDropdownOption = null;

        this.dropdownOptions.forEach(dropdownOption => {
            const option = this.dropdownOptionsMap.get(dropdownOption);

            if (!option.disabled) {
                const firstLetter = dropdownOption.textContent.charAt(0).toLowerCase();
                const letterIndex = parseInt(firstLetter, 36) - 9;
                const diff = Math.abs(searchLetterIndex - letterIndex);
                
                if (diff <= lowestDiff) {
                    lowestDiff = diff;
                    closestDropdownOption = dropdownOption;
                }
            }
        });

        if (closestDropdownOption)
            this.focusDropdownOptionByIndex(this.dropdownOptions.indexOf(closestDropdownOption));
    }

    /** ----------------------------------------
         Dropdown Direction
    ---------------------------------------- */

    /**
     * Set dropdown direction
     * @returns {void}
     */
    setDropdownDirection() {
        const rect = this.element.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const top = rect.top + scrollTop;
        const windowHeight = window.innerHeight;
        const bodyHeight = document.body.clientHeight;

        if (bodyHeight - 400 < top || windowHeight - 150 < rect.top) {
            this.element.classList.add(this.settings.revertDirectionClass);
        } else {
            this.element.classList.remove(this.settings.revertDirectionClass);
        }
    }

    /** ----------------------------------------
        Observers
    ---------------------------------------- */

    /**
     * Create select observer
     * @returns {void}
     */
    createSelectObserver() {
        this.selectObserver = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    // Handle added option node
                    Array.from(mutation.addedNodes).filter(node => node.nodeName === 'OPTION').forEach(optionNode => {
                        const option = optionNode;

                        this.options.push(option);

                        if (this.useDropdown) {
                            const dropdownOption = this.createDropdownOptionNode(option);
                            this.addDropdownOptionEvent(dropdownOption);
                        }
                    });

                    // Handle removed option node
                    Array.from(mutation.removedNodes).filter(node => node.nodeName === 'OPTION').forEach(optionNode => {
                        const option = optionNode;
                        const updatedValue = this.value.includes(option.value);

                        this.options = this.options.filter(value => value !== option);

                        if (this.useDropdown) 
                            this.removeDropdownOptionNode(option);

                        if (updatedValue)
                            this.select.dispatchEvent(new Event('change'));
                    });
                }

                if (mutation.type === 'attributes' && mutation.target.nodeName === 'OPTION') {
                    const option = mutation.target;
                    const dropdownOption = this.getDropdownOptionFromMap(option);
                    this.updateDropdownOption(dropdownOption);
                }
            });    
        });
        
        var config = { attributes: true, childList: true, characterData: true, subtree: true };
        
        this.selectObserver.observe(this.select, config);
    }

    /** ----------------------------------------
        Helpers
    ---------------------------------------- */

    /**
     * Is descendant helper class
     * This could maybe be moved to a helper package
     * @param  {Node} parent
     * @param  {HTMLElement} child
     * @returns boolean
     */
    isDescendant(parent, child) {
        let node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    /**
     * Get dropdown option from map by given value
     * @param  {HTMLOptionElement} option
     * @returns null|HTMLAnchorElement
     */
    getDropdownOptionFromMap(option) {
        const filtered = Array.from(this.dropdownOptionsMap).filter(value => value[1] === option);
        return (filtered.length === 1) ? filtered[0][0] : null;
    }

    /**
     * Get options with value
     * @returns Array
     */
    getOptionsWithValue() {
        return this.options.filter(option => option.value);
    }

    /**
     * Get selected options
     * @returns Array
     */
    getSelectedOptions() {
        return this.options.filter(option => option.selected);
    }
}