/** ----------------------------------------
    Profile Form
 ---------------------------------------- */

/**
 * The class adds js functionality that
 * are specific for the profile form.
 */

export class ProfileForm {
    /**
     * Starts the profile form specific js
     */
    constructor() {
        this.form = document.getElementById('js-account');

        if(this.form == null)
            return;

        this.avatarInput = document.getElementById('file-avatar');
        this.formChanged = false;

        this.init();
    }

    setEvents() {
        // Submit form when avater changes
        this.avatarInput.addEventListener('change', () => {
            if (window.FileReader) {
                let file = this.avatarInput.files[0];
                if(file.size > 5000000) {
                    this.avatarInput.value = null;
                    document.getElementById('file-label-avatar').innerText = 'Bestand is te groot, selecteer een ander';
                    return;
                }
            }

            this.form.submit();
        });

        // Keep track of form changes
        this.form.addEventListener('input', (e) => {
            if (e.target.id === 'file-avatar') {
                this.formChanged = false;
                return;
            }
            this.formChanged = true;
        });

        // Reset form changed when form is submitted
        this.form.addEventListener('submit', () => {
            this.formChanged = false;
        });

        // Show warning when leaving page when form got changes
        window.addEventListener('beforeunload', e => {
            if (this.formChanged) {
                e.preventDefault();
                e.returnValue = '';
                return false;
            }
        });
    }

    init() {
        this.setEvents();
    }
}
