/** ----------------------------------------
    Transform whitespaces to <br> tag
---------------------------------------- */

// https://stackoverflow.com/questions/7467840/nl2br-equivalent-in-javascript
const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

/** ----------------------------------------
     Export
 ---------------------------------------- */

export default nl2br;