/** ----------------------------------------
    Confirm
 ---------------------------------------- */

export class Confirm {

    constructor(el) {
        this.el = el;
        this.titleEl = el.querySelector('.confirm__title');
        this.openClass = 'confirm--open';
        this.isOpen = el.classList.contains(this.openClass);

        this.init();
    }

    /** ----------------------------------------
        Show/hide
     ---------------------------------------- */

    show() {
        this.el.classList.add(this.openClass);
    }

    hide() {
        this.el.classList.remove(this.openClass);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.titleEl.addEventListener('click', e => {
            e.preventDefault();
            this.isOpen = !this.isOpen;
            (this.isOpen) ? this.show() : this.hide();
        });
    }
}