var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__group" }, [
    _c("label", {
      staticClass: "label--search",
      attrs: { for: "results-search" }
    }),
    _vm._v(" "),
    _c("input", {
      attrs: {
        type: "search",
        name: "search",
        id: "results-search",
        value: "",
        placeholder: _vm.placeholder
      },
      on: { input: _vm.searching }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }