/** ----------------------------------------
    Cancel
 ---------------------------------------- */

const cancel = `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.29289 10.7071C0.902369 10.3166 0.902369 9.68342 1.29289 9.29289L4.58579 6L1.29289 2.70711C0.902369 2.31658 0.902369 1.68342 1.29289 1.29289C1.68342 0.902369 2.31658 0.902369 2.70711 1.29289L6 4.58579L9.29289 1.29289C9.68342 0.902369 10.3166 0.902369 10.7071 1.29289C11.0976 1.68342 11.0976 2.31658 10.7071 2.70711L7.41421 6L10.7071 9.29289C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071L6 7.41421L2.70711 10.7071C2.31658 11.0976 1.68342 11.0976 1.29289 10.7071Z" fill="#1E3582"/>
  </svg>
`;

const minus = `
  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.200195" width="11" height="1.5" rx="0.75" fill="#1E3582"/>
  </svg>
`;

const plus = `
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.414214 7.07107C0.414214 6.51878 0.861929 6.07107 1.41421 6.07107L6.07107 6.07107L6.07107 1.41421C6.07107 0.861929 6.51878 0.414214 7.07107 0.414214C7.62335 0.414214 8.07107 0.861929 8.07107 1.41421L8.07107 6.07107L12.7279 6.07107C13.2802 6.07107 13.7279 6.51878 13.7279 7.07107C13.7279 7.62335 13.2802 8.07107 12.7279 8.07107L8.07107 8.07107L8.07107 12.7279C8.07107 13.2802 7.62335 13.7279 7.07107 13.7279C6.51878 13.7279 6.07107 13.2802 6.07107 12.7279V8.07107L1.41421 8.07107C0.861929 8.07107 0.414214 7.62335 0.414214 7.07107Z" fill="#1E3582"/>
  </svg>
`;

const loading = `
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M10,0 C15.1774253,0 19.4475626,3.95146272 19.9504682,9.00023733 L18.1903348,9.00049071 C17.698443,4.9102318 14.2249319,1.75 9.99998961,1.75 C5.7750666,1.75 2.30155689,4.9102318 1.80966516,9.00049071 L1.80966516,9.00049071 L0.0495071804,9.00023733 C0.552169508,3.95146272 4.82038406,0 10,0 Z" fill="currentColor" fill-rule="nonzero">
      <animateTransform attributeType="xml"
      attributeName="transform"
      type="rotate"
      from="0 10 10"
      to="360 10 10"
      dur="0.8s"
      repeatCount="indefinite"/>
    </path>
  </svg>
`;

/** ----------------------------------------
    Exports
 ---------------------------------------- */

export {
  cancel,
  minus,
  plus,
  loading,
}
