/** ----------------------------------------
    Social Share
 ---------------------------------------- */

/**
 * Use this to share a page on social media,
 * with custom text / urls. You can also copy
 * text to clipboard, see below 'copyToClipBoard'
 */

export default class SocialShare {

    /**
     * @element         element that inherits interaction
     * @default         default configuration
     * @tigger          element that triggers interaction
     * @url             current page url
     * @type            default social type
     * @init            calls the init method
     */

    constructor(element) {
        this.elem = element;
        this.config = JSON.parse(this.elem.dataset.config);
        this.trigger = element;
        this.url = window.location.href;
        this.type = 'facebook';
        this.init();
    }

    /** ----------------------------------------
        Add Event
     ---------------------------------------- */

    addEvents() {
        this.trigger.onclick = (e) => {
            e.preventDefault();
            this.shareElement(e);
        };
    }

    /** ----------------------------------------
        Share Type
     ---------------------------------------- */

    shareElement() {
        let shareURL = '';

        if(this.type === 'copy'){
            this.copyToClipBoard();
            return false;
        }

        if(this.type === 'twitter') {
            shareURL = 'http://twitter.com/share?url=';
        }
        if(this.type === 'facebook') {
            shareURL = 'https://www.facebook.com/sharer/sharer.php?u=';
        }
        if(this.type === 'google-plus') {
            shareURL = 'https://plus.google.com/share?url=';
        }
        if(this.type === 'whatsapp') {
            shareURL = 'https://api.whatsapp.com/send?text=';
        }
        if(this.type === 'linkedin') {
            shareURL = 'https://www.linkedin.com/shareArticle?mini=true&url=';
        }

        window.open(
            shareURL + this.url,
            this.type.charAt(0).toUpperCase(),
            'height=450, width=550, top='+(window.innerHeight/2 - 225) +', left='+window.innerWidth/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
        );
    }

    /** ----------------------------------------
        Copy
     ---------------------------------------- */

    copyToClipBoard() {
        const share_url = document.querySelector('.js-copy-url');
        const selection = window.getSelection();
        const range = document.createRange();
        share_url.innerHTML = this.url;
        range.selectNodeContents(document.querySelector('.js-copy-url'));
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        this.trigger.classList.add('is-active');
        this.snackbar();
    }

    /** ----------------------------------------
        Snackbar
     ---------------------------------------- */

    snackbar() {
        const copy_msg = document.querySelector('.js-copy-msg');
        copy_msg.classList.add('is-hidden');

        setTimeout(function() {
            copy_msg.classList.remove('is-hidden');
        }, 2000);
    }
    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.type = (this.config.type !== undefined) ? this.config.type : this.type;
        this.url = (this.config.customurl !== undefined) ? this.config.customurl : this.url;
        this.addEvents();
    }
}
