<template>
    <a :href="data.link" class="card result-card results__row">
        <div class="result-card__content">
            <div class="meta meta--medium">
                <span class="meta__avatar avatar">
                    <img class="avatar__image" v-if="hasAvatar(data.author.avatar)" :src="data.author.avatar">
                    <span class="avatar__initials" v-if="!hasAvatar(data.author.avatar)">{{ data.author.avatar }}</span>
                </span>
                <span class="meta__author">{{ data.author.name }}</span>
            </div>
            <div class="card__title result-card__title">{{ data.title }}</div>
        </div>
        <div class="card__tags result-card__tags">
            <span v-for="tag in data.categories" class="tag-solid--lightgrey">{{ tag }}</span>
        </div>
        <div class="result-card__debug" v-if="debug">Thema: {{ data.themes }} | Type: {{ data.type }}</div>
    </a>
</template>

<script>

    export default {

        name: 'Row',

        /* ----------------------------------------
            Data
        ---------------------------------------- */

        /**
         * If needed set debug to true to display
         * values (ids) of related filter values.
         */

        data() {
            return {
                debug: false
            }
        },

        /* ----------------------------------------
            Props
        ---------------------------------------- */

        /**
         * The data object contains all the information
         * that has to be displayed within the render
         * of this card element.
         */

        props: ['data'],

        /* ----------------------------------------
             Methods
         ---------------------------------------- */

        methods: {

            /* ----------------------------------------
                Has Avatar
            ---------------------------------------- */

            /**
             * Will check if given string is an image
             */

            hasAvatar(src) {
                if (!src) { return null }
                return src.match(/\.(jpeg|jpg|gif|png)$/) != null;
            }
        }

    };
</script>