<template>
    <div class="form__group">
        <label class="label--search" for="results-search"></label>
        <input type="search" name="search" id="results-search" value="" :placeholder="placeholder" @input="searching">
    </div>
</template>

<script>

    /* ----------------------------------------
        Modules
    ---------------------------------------- */

    import { debounce } from 'lodash';

    /* ----------------------------------------
        Component
    ---------------------------------------- */

    export default {

        name: 'Search',

        /* ----------------------------------------
            Props
        ---------------------------------------- */

        /**
         * The placeholder contains the text displayed
         * within the search bar before the user starts
         * typing.
         */

        props: ['placeholder'],

        /* ----------------------------------------
            Searching
        ---------------------------------------- */

        /**
         * While a user is searching a debounce will
         * delay the event from taking place and
         * dispatch it after a given amount of
         * milliseconds to update the render.
         */

        created() {
            this.searching = _.debounce(e => {
                this.$emit('searched', {
                    value: e.target.value
                });
            }, 250)
        }

    };

</script>