/* ----------------------------------------
    Node Modules
---------------------------------------- */

import { Toast } from '@js/module/Toast';

const toaster = new Toast();

/** ----------------------------------------
    Helpers
 ---------------------------------------- */

/**
 * The functions listed within this class
 * will help you do some basic checks and
 * consists out of common used functions
 * helpful during development.
 *
 * These functions all use well tested code
 * and are optimised for better performance.
 */

export class Helpers {

    /** ----------------------------------------
        Get Type
     ---------------------------------------- */

    getType(value) {
        if (typeof value === 'undefined') return 'undefined';
        if (typeof value === 'object' && !value) return 'null';
        return ({}).toString.call(value).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
    }

    /** ----------------------------------------
        Check Array
     ---------------------------------------- */

    isArray(value) {
        return value.constructor === Array;
    }

    /** ----------------------------------------
        Check Object
     ---------------------------------------- */

    isObject(value) {
        if (value === null) {
            return false;
        }
        return ((typeof value === 'function') || (typeof value === 'object'));
    }

    /** ----------------------------------------
        Check String
     ---------------------------------------- */

    isString(value) {
        return typeof value === 'string' || ((!!value && typeof value === 'object') && Object.prototype.toString.call(value) === '[object String]');
    }

    /** ----------------------------------------
        Check Boolean
     ---------------------------------------- */

    isBoolean(value) {
        return obj === true || obj === false || toString.call(obj) === '[object Boolean]';
    }

    /** ----------------------------------------
        Check Number
     ---------------------------------------- */

    isNumber(value) {
        return !isNaN(+n) && isFinite(n);
    }

}

function triggerSelectEvent(el) {
    el.classList.add('clicked');
    let evObj = document.createEvent('MouseEvents');
    evObj.initEvent('mousedown', true, false);
    el.dispatchEvent(evObj);
}

function validateFormFiles(form) {
    const fileInputs = form.querySelectorAll('input[type="file"]');

    let files = [];
    fileInputs.forEach(fileInput => files = [...files, ...fileInput.files]);

    let fileMbLimit = 5;
    let totalFilesMbLimit = 25;
    let totalSize = 0;
    let valid = true;

    for (let file of files) {
        if (file.size > fileMbLimit * 1000 * 1024) {
            toaster.setToast(`Een bestand die u wilt uploaden is groter dan de limiet van ${fileMbLimit}MB (${file.name}).`);
            valid = false;
            break;
        }

        totalSize+= file.size;
    }

    if (valid && totalSize > totalFilesMbLimit * 1000 * 1024) {
        toaster.setToast(`De bestanden die u wilt uploaden overschrijven de totale limiet van ${totalFilesMbLimit}MB.`);
        valid = false;
    }

    return valid;
}

function isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
        if (node === parent)
            return true;
        node = node.parentNode;
    }
    return false;
}

/** ----------------------------------------
    Export Functions
 ---------------------------------------- */

export {
    triggerSelectEvent,
    validateFormFiles,
    isDescendant
};
