/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';
import axios from 'axios';
import { cancel, loading } from '../templates/icons';

/* ----------------------------------------
    Animations
---------------------------------------- */

const openAnimation = (el, height) => anime({
    targets: el,
    height: [0, height],
    opacity: [0, 1],
    easing: 'easeOutQuad',
    duration: 300,
    complete: () => {
        el.style.height = 'auto';
        el.style.overflow = 'visible';
    }
});

const closeAnimation = (el) => anime({
    targets: el,
    height: 0,
    opacity: [1, 0],
    easing: 'easeOutQuad',
    duration: 250,
    begin: () => {
        el.style.overflow = 'hidden';
    },
    complete: () => {
        el.style.height = 0;
    }
});

/* ----------------------------------------
    Popup
---------------------------------------- */

export class EditForm {

    constructor(el) {
        this.el = el;
        this.autosave = this.el.dataset.autosave !== undefined;
        this.sectionElems = this.el.querySelectorAll('.edit__section');
        this.sections = Array.from(this.sectionElems).map(sectionEl => {
            const changeEl = sectionEl.querySelector('.edit__change');

            if (changeEl)
                changeEl.originalInnerHTML = changeEl.innerHTML;

            return {
                el: sectionEl,
                changeEl: changeEl,
                type: sectionEl.dataset.type,
                holderEl: sectionEl.querySelector('.edit__holder'),
                deleteEl: sectionEl.querySelector('.edit__delete'),
                saveEl: sectionEl.querySelector('.edit__save'),
                valueEl: sectionEl.querySelector('.edit__value'),
                fieldElems: sectionEl.querySelectorAll('input, textarea, select'),
            };
        });
        this.formChanged = false;

        this.init();
    }

    addEvents() {
        this.sections.forEach(section => {
            // Add section toggle
            if (section.changeEl) {
                section.changeEl.addEventListener('click', () => {
                    this.toggleSection(section);
                });
            }

            // Add section delete
            if (section.deleteEl) {
                section.deleteEl.addEventListener('click', () => {
                    this.emptySectionValues(section);
                });
            }

            // Add autosave
            if (section.saveEl && this.autosave) {
                section.fieldElems.forEach(fieldEl => {
                    fieldEl.addEventListener('change', ev => section.saveEl.disabled = false);
                    fieldEl.addEventListener('input', ev => section.saveEl.disabled = false);
                });

                section.saveEl.addEventListener('click', ev => {
                    ev.preventDefault();
                    this.autosaveFields(section);
                });
            }
        });

        // Keep track of form changes
        this.el.addEventListener('input', (e) => {
            this.formChanged = true;
        });

        // Reset form changed when form is submitted
        this.el.addEventListener('submit', () => {
            this.formChanged = false;
        });

        // Show warning when leaving form when form got changes
        window.addEventListener('beforeunload', e => {
            if (this.formChanged) {
                e.preventDefault();
                e.returnValue = '';
                return false;
            }
        });
    }

    /** ----------------------------------------
        Autosave
     ---------------------------------------- */

    autosaveFields(section) {
        const formData = this.getFieldValues(section);

        section.fieldElems.forEach(fieldNode => {
            fieldNode.disabled = true;
        });

        section.saveEl.disabled = true;

        if (section.valueEl) {
            section.valueEl.innerHTML = `${loading}Bezig met opslaan`;
            section.valueEl.classList.add('is-saving');
            section.valueEl.classList.remove('has-error');
        }

        this.callEndoint(section, formData);
    }

    getFieldValues(section) {
        const formData = new FormData();
        const baseFieldElems = this.el.querySelectorAll('input[name="_token"], input[name="_method"]');

        [...baseFieldElems, ...section.fieldElems].forEach(fieldNode => {
            if (['radio', 'checkbox'].includes(fieldNode.type)) {
                if (!formData.get(fieldNode.name))
                    formData.append(fieldNode.name, null);

                if (fieldNode.checked)
                    formData.append(fieldNode.name, fieldNode.value);
            } else if (fieldNode.type === 'file') {
                formData.append(fieldNode.name, fieldNode.files[0]);
            } else if (fieldNode.type === 'select-multiple') {
                [...fieldNode.options]
                    .filter((x) => x.selected)
                    .map((x) => formData.append(fieldNode.name+'[]', x.value));
            } else {
                formData.append(fieldNode.name, fieldNode.value);
            }
        });

        return formData;
    }

    callEndoint(section, formData) {
        axios.post(this.el.action, formData, { 'Content-Type': 'multipart/form-data' })
            .then(response => {
                this.handleSuccess(response, section);
                this.handleFinish(section);
            })
            .catch(error => {
                this.handleError(error, section);
                this.handleFinish(section);
            });
    }

    emptySectionValues(section) {
        section.fieldElems.forEach(fieldEl => {
            fieldEl.value = '';
        });

        this.autosaveFields(section);
    }

    /** ----------------------------------------
        handle responses
     ---------------------------------------- */

    handleSuccess(response, section) {
        if (section.valueEl) {
            section.valueEl.innerHTML = response.data[section.valueEl.dataset.name];
        }

        this.closeSection(section);
    }

    handleError(error, section) {
        if (section.valueEl) {
            section.valueEl.innerHTML = `${cancel}Opslaan mislukt`;
            section.valueEl.classList.add('has-error');
        }

        section.saveEl.disabled = false;
    }

    handleFinish(section) {
        if (section.valueEl) {
            section.valueEl.classList.remove('is-saving');
        }

        section.fieldElems.forEach(fieldNode => {
            fieldNode.disabled = false;
        });

        this.formChanged = false;
    }

    /** ----------------------------------------
        Section
     ---------------------------------------- */

    toggleSection(section) {
        (!section.holderEl.classList.contains('is-active'))
            ? this.openSection(section)
            : this.closeSection(section);
    }

    openSection(section) {
        section.changeEl.classList.add('is-active');
        section.holderEl.classList.add('is-active');

        if (section.deleteEl)
            section.deleteEl.style.display = 'none';

        this.updateChangeTitle(section, true);
        this.focusFirstSectionField(section.holderEl);
        const height = section.holderEl.children[0].offsetHeight;
        return openAnimation(section.holderEl, height);
    }

    closeSection(section) {
        section.changeEl.classList.remove('is-active');
        section.holderEl.classList.remove('is-active');

        if (section.deleteEl)
            section.deleteEl.style.display = '';

        this.updateChangeTitle(section, false);
        return closeAnimation(section.holderEl);
    }

    updateChangeTitle(section, open) {
        if (open) {
            section.changeEl.innerHTML = `Sluiten${cancel}`;
        } else {
            section.changeEl.innerHTML = section.changeEl.originalInnerHTML;

            if (section.valueEl) {
                setTimeout(() => {
                    section.changeEl.children[0].style.display = section.valueEl.innerHTML ? 'flex' : 'none';
                    section.changeEl.children[1].style.display = section.valueEl.innerHTML ? 'none' : 'flex';
                }, 100);
            }
        }
    }

    focusFirstSectionField(holderEl) {
        const fields = holderEl.querySelectorAll('input, textarea');
        if (fields.length) {
            const field = fields[0];
            field.select();
        }
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.addEvents();
    }
}
