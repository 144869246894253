/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/* ----------------------------------------
    Animations
---------------------------------------- */

const openAnimation = el => anime({
    targets: el,
    opacity: [0, 1],
    easing: 'easeOutQuad',
    duration: 250,
    begin: () => {
        el.style.display = 'block';
    }
});

const closeAnimation = el => anime({
    targets: el,
    opacity: [1, 0],
    easing: 'easeOutQuad',
    duration: 250,
    complete: () => {
        el.style.display = 'none';
    }
});

/* ----------------------------------------
    Popup
---------------------------------------- */

export class Popup {

    constructor(settings) {
        if(settings) {
            this._active = settings.active;
            this._enlarge = settings.enlarge;
            this._injected = false;
            this._popup = settings.popup;
            this._open = settings.open;
            this._html = settings.html;
        }

        this._init();
    }

    /* ----------------------------------------
        Set Active
    ---------------------------------------- */

    _setActive(el) {
        const active = this._active || 'is-active';
        const element = el.classList.contains(active);
        element ? el.classList.add(active) : el.classList.remove(active);
    }

    /* ----------------------------------------
        Open Popup
    ---------------------------------------- */

    set open(popup) {
        this._popup = document.querySelector(popup);
        openAnimation(this._popup);
        this._closePopup();
    }

    _openPopup() {
        const open = this._open;
        if(!open) return;

        this._open = open;

        open.addEventListener('click', () => {
            this._setActive(this._popup);
            this._setContent();
            this._setTemplate();
            this._setClose();
            this._setEnlarge();
            openAnimation(this._popup);
        });

        document.body.classList.add('is-locked');
    }

    /* ----------------------------------------
        Close Popup
    ---------------------------------------- */

    get close() {
        return closeAnimation(this._popup);
    }

    _closePopup() {
        const close = this._popup.querySelector('.popup__close');
        if(!close) return;

        close.addEventListener('click', () => {
            this._setActive(this._popup);

            this.close.finished.then(() => {
                if(this._content) this._reduceContent();
                if(this._injected) this._cleanContent();
            });

            close.removeEventListener('click', this);
        });

        document.body.classList.remove('is-locked');
    }

    _setClose() {
        if(!this._close) return;
    }

    /* ----------------------------------------
        Popup Content
    ---------------------------------------- */

    _setContent() {
        const open = this._open;

        if(open && open.dataset.content && !this._html) {
            this._content = {
                data: JSON.parse(open.dataset.content),
                el: this._popup.querySelector('.popup__content')
            };

            const content = this._createContent(this._content.data);

            this._injectContent({
                el: this._popup,
                data: content
            });

            this._enlargeContent(this._content.el);
        }
    }

    /* ----------------------------------------
        Set Large Content
    ---------------------------------------- */

    _setEnlarge() {
        if(!this._enlarge || !this._content.el) return;
        this._enlargeContent(this._content.el);
    }

    _enlargeContent() {
        if(!this._content.el) return;
        this._content.el.classList.add('popup__content--large');
    }

    _reduceContent() {
        if(!this._content) return;
        if(!this._content.el) return;
        this._content.el.classList.remove('popup__content--large');
    }

    /* ----------------------------------------
        Create Content
    ---------------------------------------- */

    _createContent(data) {
        const { title, info } = data;

        const html = `
            ${ title && `<div class="popup__title">${ title }</div>` }
            ${ info && `<div class="popup__info">${ info }</div>` }
        `;

        return html;
    }

    _cleanContent() {
        const template = this._popup.querySelector('.popup__template');
        template.innerHTML = '';
    }

    /* ----------------------------------------
        Set Template
    ---------------------------------------- */

    _setTemplate() {
        if(!this._html) return;

        this._content = {
            data: this._html,
            el: this._popup.querySelector('.popup__content')
        };

        this._injectContent(this._content);
    }

    /* ----------------------------------------
        InjectContent
    ---------------------------------------- */

    _injectContent(content) {
        this._injected = true;

        const { data, el } = content;
        if(!el) return;

        const template = el.querySelector('.popup__template');
        template.innerHTML = data;
    }

    /* ----------------------------------------
        Initialize
    ---------------------------------------- */

    _init() {
        this._popup = document.querySelector(this._popup);
        this._open = document.querySelector(this._open);

        if(!this._popup) return;

        document.body.classList.add('is-locked');

        this._openPopup();
        this._closePopup();
    }

}