import { isDescendant } from './../helpers/Helpers';

/** ----------------------------------------
    Tabs
 ---------------------------------------- */

export class Tabs {

    constructor(element) {
        this.element = element;
        this.namespace = this.element.dataset.tabNamespace;
        this.tabElems = document.querySelectorAll(`.js-tab[data-tab-namespace="${this.namespace}"]`);
        this.tabs = Array.from(this.tabElems).map(tabEl => {
            const slug = tabEl.dataset.tabSlug;
            const triggerElems = document.querySelectorAll(`[data-activate-tab="${slug}"]`);
            const triggers = Array.from(triggerElems).map(triggerEl => {
                return {
                    el: triggerEl,
                    inHeader: isDescendant(this.element, triggerEl)
                };
            });
            return {
                el: tabEl,
                id: tabEl.id,
                slug: slug,
                triggers: triggers,
            };
        });
        this.init();
    }

    clickEvent() {
        this.tabs.forEach(tab => {
            tab.triggers.forEach(trigger => {
                trigger.el.addEventListener('click', ev => {
                    ev.preventDefault();
                    this.activateTab(tab);
                });
            });
        });
    }

    activateTabByHash() {
        let hashTab;
        const hash = window.location.hash.replace('#', '');

        this.tabs.forEach(tab => {
            if (tab.id === hash)
                hashTab = tab;
        });

        this.activateTab(hashTab ? hashTab : this.tabs[0]);
    }

    activateTab(_tab) {
        this.tabs.forEach(tab => {
            tab.el.style.display = (_tab.slug === tab.slug) ? 'block' : 'none';

            tab.triggers.forEach(trigger => {
                trigger.el.classList.toggle('is-active', _tab.slug === tab.slug);
            });
        });

        history.replaceState(null, null, window.location.pathname + '#' + _tab.id);
    }

    init() {
        this.activateTabByHash();
        this.clickEvent();
    }
}
