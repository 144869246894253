/* ----------------------------------------
    Node Modules
---------------------------------------- */

import axios from 'axios';
import auth from '@js/utilities/auth';

/** ----------------------------------------
    News Overview Loader
 ---------------------------------------- */

/**
 * This class will handle all functionality
 * to load extra news when an element scrolls into view.
 */

export class NewsOverviewLoader {

    /**
     * Checks if the extra loading grid exists and initialises
     * the rest of the functionalities
     */

    constructor() {
        this.containerEl = document.getElementById('news-items');
        if(this.containerEl === null) {
            return;
        }

        this.observableEl = document.querySelector('#load-news');
        this.endpoint = this.observableEl.dataset.endpoint;
        this.nextPaginationPage = 2;
        this.fetchingData = false;

        this._init();
    }

    /** ----------------------------------------
     Handle the load into view action
     ---------------------------------------- */

    /**
     * This action gets the next paginated results for
     * the article overview page and adds them to the end.
     * When there are no news left, the element which is
     * observed to load new news is unobserved.
     */

    _handleIntersection() {
        this.fetchingData = true;
        axios.get(`${ this.endpoint }?page=${ this.nextPaginationPage }`, auth(this.endpoint).headers)
            .then(response => {
                if(response.data === null || response.data === '') {
                    this.observer.unobserve(this.observableEl);
                } else {
                    this.nextPaginationPage += 1;
                    let lastElementChild = this.containerEl.lastElementChild;
                    if(lastElementChild === null) {
                        this.containerEl.innerHTML = response.data;
                    } else {
                        lastElementChild.insertAdjacentHTML('afterend', response.data);
                    }
                }
            })
            .finally(() => {
                this.fetchingData = false;
            });
    }

    /** ----------------------------------------
     Initialize
     ---------------------------------------- */

    /**
     * This function adds an observer to the bottom of the page
     * that when it scrolls into view, new news are gettings retrieved.
     */

    _init() {
        this.observer = new IntersectionObserver(entries => {
            if(this.fetchingData) return;

            if(entries[0].isIntersecting) {
                this._handleIntersection();
            }
        }, { threshold: [0] });
        this.observer.observe(this.observableEl);
    }
}
