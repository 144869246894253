/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/* ----------------------------------------
    Animations
---------------------------------------- */

const openMenu = el => anime({
    targets: el,
    opacity: [0, 1],
    scale: [1.1, 1],
    easing: 'easeOutQuad',
    duration: 350,
    begin: () => {
        el.style.display = 'block';
    }
});

const closeMenu = el => anime({
    targets: el,
    opacity: [1, 0],
    scale: [1, 1.1],
    easing: 'easeOutQuad',
    duration: 350,
    complete: () => {
        el.style.display = 'none';
    }
});

/* ----------------------------------------
    Mobile Menu
---------------------------------------- */

export class MobileMenu {

    constructor() {
        this._init();
    }

    _open() {
        openMenu(this._mobile);
    }

    _close() {
        closeMenu(this._mobile);
    }

    _event() {
        this._openMenu.addEventListener('click', () => {
            this._open();
        });

        this._closeMenu.addEventListener('click', () => {
            this._close();
        });
    }

    _init() {
        this._mobile = document.querySelector('.mobile');
        if(!this._mobile) return;

        this._openMenu = document.querySelector('.nav__hamburger');
        this._closeMenu = document.querySelector('.nav__close');
        this._event();
    }

}
