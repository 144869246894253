<template>
    <div class="comments" ref="container">

        <!-- INFO -->
        <div class="comments__info">
            <div class="comments__title-wrapper">
                <h4 class="comments__title section__title section__title--medium">{{ title }}</h4>
                <span class="comments__amount" v-if="commentsAmount">{{ commentsAmount }}</span>
            </div>
            <a class="comments__react btn btn-solid--lightblue btn--small" @click="reactIsClicked">Reageer</a>
        </div>

        <!-- COMMENTS -->
        <Comment v-for="comment in comments" :data="comment" :liked="commentIsLiked(comment)"></Comment>

        <!-- REACTION -->
        <Reaction ref="reaction" @reacted="onReact"></Reaction>
    </div>
</template>

<script>

    /* ----------------------------------------
        Node Modules
    ---------------------------------------- */

    import axios from 'axios';

    /* ----------------------------------------
        Authenticator
    ---------------------------------------- */

    import auth from '@js/utilities/auth';

    /* ----------------------------------------
        Modules
    ---------------------------------------- */

    import Comment from './Comment.vue';
    import Reaction from './Reaction.vue';

    /* ----------------------------------------
        Component
    ---------------------------------------- */

    export default {

        name: 'Comments',

        /* ----------------------------------------
            Data
        ---------------------------------------- */

        data() {
            return {
                title: 'Reacties',
                comments: [],
                likes: [],
            };
        },

        /* ----------------------------------------
            Components
        ---------------------------------------- */

        components: {
            Comment,
            Reaction
        },

        /* ----------------------------------------
            Amount of Comments
        ---------------------------------------- */

        computed: {
            commentsAmount() {
                return this.comments.length;
            }
        },

        /* ----------------------------------------
            Comment Event
        ---------------------------------------- */

        methods: {
            onReact(value) {
                this.comments.push(value);
            },

            commentIsLiked(comment) {
                return this.likes.includes(comment.id);
            },

            reactIsClicked() {
                this.$refs.reaction.$el.querySelector('textarea').focus();
            }
        },

        /* ----------------------------------------
            Mounted
        ---------------------------------------- */

        mounted() {
            const main = document.querySelector('.main');
            const id = main.dataset.id;
            const fetch = auth(`/api/comments/${ id }`);

            axios.get(`/api/comments/${ id }`, fetch.headers)
                .then(response => {
                    this.comments = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });

            axios.get(`/api/like`, fetch.headers)
                .then(response => {
                    this.likes = response.data.comments;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
</script>
