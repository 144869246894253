/** ----------------------------------------
     Authorisation
 ---------------------------------------- */

const auth = api => {
    const url = window.location.hostname;
    const fetch = url + api;
    const token = document.body.dataset.token;

    return {
        host: url,
        url: fetch,
        token: token,
        headers: {
            headers: {
                'user-token': token
            }
        }
    };
};

/** ----------------------------------------
     Export
 ---------------------------------------- */

export default auth;