var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "comment", staticClass: "card comment-card" }, [
    _c("div", { staticClass: "comment-card__avatar" }, [
      _c("span", { staticClass: "avatar avatar--large" }, [
        _vm.hasAvatar(_vm.data.author.avatar)
          ? _c("img", {
              staticClass: "avatar__image",
              attrs: { src: _vm.data.author.avatar }
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.hasAvatar(_vm.data.author.avatar)
          ? _c("span", { staticClass: "avatar__initials" }, [
              _vm._v(_vm._s(_vm.data.author.avatar))
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "comment-card__content" }, [
      _c(
        "div",
        { staticClass: "card__meta comment-card__meta meta meta--small" },
        [
          _c(
            "a",
            {
              staticClass:
                "meta__author meta__author--large meta--black meta--bold",
              attrs: { href: _vm.data.author.url }
            },
            [_vm._v(_vm._s(_vm.data.author.name))]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "meta__role meta--black meta--sep-dot" }, [
            _vm._v(_vm._s(_vm.data.author.school))
          ]),
          _vm._v("\n             \n            "),
          _c("span", { staticClass: "meta__time" }, [
            _vm._v(_vm._s(_vm.time(_vm.data.time)))
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "comment-card__comment",
        domProps: { innerHTML: _vm._s(_vm.commentHtml) }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "comment-card__like" }, [
      _c(
        "button",
        {
          staticClass: "like meta__like",
          class: { "is-liked": _vm.liked },
          attrs: {
            id: "comment-like-button-" + _vm.data.id,
            "data-id": _vm.data.id
          },
          on: {
            click: function($event) {
              return _vm.handleLike($event)
            }
          }
        },
        [
          _c("Heart"),
          _vm._v(" "),
          _c("span", { attrs: { id: "comment-like-counter-" + _vm.data.id } }, [
            _vm._v(_vm._s(_vm.data.likes))
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }