/* ----------------------------------------
    Node Modules
---------------------------------------- */

import axios from 'axios';
import anime from 'animejs';

/** ----------------------------------------
     Utilities
 ---------------------------------------- */

import auth from '@js/utilities/auth';

/* ----------------------------------------
    Notification
---------------------------------------- */

export class Notification {

    constructor(el) {
        this.el = el;

        if (!this.el) return;

        this.closeEl = this.el.querySelector('.js-notification-close');
        this.buttonEl = this.el.querySelector('.js-notification-button');
        
        this._init();
    }

    close() {
        anime({
            targets: this.el,
            height: 0,
            marginTop: 0,
            paddingTop: 0,
            opacity: [1, 0],
            easing: 'easeOutQuart',
            duration: 700,
            begin: () => {
                this.el.style.display = 'block';
            }
        });
    }

    _events() {
        [this.closeEl, this.buttonEl].forEach(link => {
            if (!link) return;
            
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const target = event.target;

                if (target.clicked) return;

                target.clicked = true;

                const endpoint = target.dataset.endpoint;

                axios.get(endpoint, auth(endpoint).headers).then(() => {
                    if (target.href) {
                        window.location.href = target.href;
                    } else {
                        this.close();
                    }
                });
            });
        });
    }

    _init() {
        this._events();
    }
}
