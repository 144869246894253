var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "comments" },
    [
      _c("div", { staticClass: "comments__info" }, [
        _c("div", { staticClass: "comments__title-wrapper" }, [
          _c(
            "h4",
            {
              staticClass:
                "comments__title section__title section__title--medium"
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _vm.commentsAmount
            ? _c("span", { staticClass: "comments__amount" }, [
                _vm._v(_vm._s(_vm.commentsAmount))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "comments__react btn btn-solid--lightblue btn--small",
            on: { click: _vm.reactIsClicked }
          },
          [_vm._v("Reageer")]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.comments, function(comment) {
        return _c("Comment", {
          attrs: { data: comment, liked: _vm.commentIsLiked(comment) }
        })
      }),
      _vm._v(" "),
      _c("Reaction", { ref: "reaction", on: { reacted: _vm.onReact } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }