<template>
    <div class="card comment-card" ref="comment">

        <!-- AVATAR -->
        <div class="comment-card__avatar">
             <span class="avatar avatar--large">
                <img class="avatar__image" v-if="hasAvatar(data.author.avatar)" :src="data.author.avatar">
                <span class="avatar__initials" v-if="!hasAvatar(data.author.avatar)">{{ data.author.avatar }}</span>
            </span>
        </div>

        <!-- CONTENT -->
        <div class="comment-card__content">
            <div class="card__meta comment-card__meta meta meta--small">
                <a :href="data.author.url" class="meta__author meta__author--large meta--black meta--bold">{{ data.author.name }}</a>
                <span class="meta__role meta--black meta--sep-dot">{{ data.author.school }}</span>
                &nbsp;
                <span class="meta__time">{{ time(data.time) }}</span>
            </div>
            <div class="comment-card__comment" v-html="commentHtml"></div>
        </div>

        <!-- LIKE -->
        <div class="comment-card__like">
            <button class="like meta__like" :id="'comment-like-button-' + data.id" :class="{ 'is-liked': liked }" :data-id="data.id" @click="handleLike($event)">
                <Heart></Heart> <span :id="'comment-like-counter-' + data.id">{{ data.likes }}</span>
            </button>
        </div>

    </div>
</template>

<script>

    /* ----------------------------------------
        Node Modules
    ---------------------------------------- */

    import moment from 'moment';
    import axios from 'axios';

    /* ----------------------------------------
        Icons
    ---------------------------------------- */

    import Heart from '@js/vue/svg/heart.vue';

    /* ----------------------------------------
       Utilities
   ---------------------------------------- */

    import auth from '@js/utilities/auth';
    import escapeHtml from '@js/utilities/escapeHtml';
    import nl2br from '@js/utilities/nl2br';

    /* ----------------------------------------
         Component
     ---------------------------------------- */

    export default {

        name: 'Comment',

        /* ----------------------------------------
             Data
         ---------------------------------------- */

        data() {
            return {
                now: Date.now(),
                processingLike: false
            };
        },

        /* ----------------------------------------
            Components
        ---------------------------------------- */

        components: {
            Heart
        },

        /* ----------------------------------------
            Computed
        ---------------------------------------- */

        computed: {
            commentHtml() {
                return nl2br(escapeHtml(this.data.comment));
            }
        },

        /* ----------------------------------------
             Methods
         ---------------------------------------- */

        methods: {
            time(stamp) {
                moment.locale('nl');
                return moment.duration(stamp - (this.now / 1000), 'seconds').humanize(true);
            },

            handleLike(e) {
                if(this.processingLike) return;

                this.processingLike = true;

                let fetch = auth(`/api/like`);
                axios.put(`/api/like`, {
                    id: this.data.id,
                    type: 'comment'
                }, fetch.headers)
                    .then(response => {
                        let likeButton = document.getElementById('comment-like-button-' + this.data.id);

                        likeButton.classList.contains('is-liked') ?
                            likeButton.classList.remove('is-liked') :
                            likeButton.classList.add('is-liked');

                        document.getElementById('comment-like-counter-' + this.data.id).textContent = response.data.like_count
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => { this.processingLike = false });
            },

            /* ----------------------------------------
                Has Avatar
            ---------------------------------------- */

            /**
             * Will check if given string is an image
             */

            hasAvatar(src) {
                if(!src) return;
                return src.match(/\.(jpeg|jpg|gif|png)$/) != null;
            }
        },

        /* ----------------------------------------
            Props
        ---------------------------------------- */

        /**
         * The data object contains all the information
         * that has to be displayed within the render
         * of this card element.
         */

        props: ['data', 'liked'],

        /* ----------------------------------------
            Created
        ---------------------------------------- */

        created () {
            setInterval(() => {
                this.now = Date.now();
            }, 1000);
        },

    };
</script>
