const threadMapping = {
    themes: [
        {
            id: 1,
            name: 'NOB Voordeel'
        },
        {
            id: 2,
            name: 'Startes'
        },
        {
            id: 3,
            name: 'Organisatie'
        }
    ]
};

const threadExample = [
    {
        id: 1,
        link: '/url',
        author: {
            name: 'Sander Hidding',
            image: 'image.png'
        },
        title: 'Lorem ipsum',
        theme: {
            id: 1,
            name: 'NOB Voordeel',
            color: 'green'
        },
        themes: [1],
        comments: 23,
        time: 1569937001,
        authors: {
            total: 0,
            avatars: [
                'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_57e8cd79bad42d94785ef128365b6147f51c0efb658ff.png',
                'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_573b187d86e41f2031f85ebd63fecbf8c7a3e9aa18009.png'
            ]
        }
    }
];

const schoolsMapping = {
    types: [
        {
            id: 1,
            name: 'Basisonderwijs'
        },
        {
            id: 2,
            name: 'Voortgezet onderwijs'
        },
        {
            id: 3,
            name: 'Speciaal onderwijs'
        }
    ],
    countries: [
        {
            id: 1,
            name: 'Nederland'
        },
        {
            id: 2,
            name: 'Duitsland'
        },
        {
            id: 3,
            name: 'Rusland'
        }
    ]
};

const schools = [
    {
        id: 1,
        link: '/url',
        name: 'Basisschool de Regenboog',
        country: 'Nederland',
        city: 'Den Haag',
        countries: [1],
        type: 'Basisonderwijs',
        types: [1],
        authors: [
            {
                name: 'Sander Hidding',
                image: 'image.png'
            },
            {
                name: 'Giovanni Marchese',
                image: 'image.png'
            },
            {
                name: 'Yira Rodriguez Garcia',
                image: 'image.png'
            }
        ]
    }
];

const resultsMapping = {
    //categories/tags
    themes: [
        {
            id: 1,
            name: 'Methodes'
        },
        {
            id: 2,
            name: 'Lesmateriaal'
        },
        {
            id: 3,
            name: 'NOB Voordeel'
        },
        {
            id: 4,
            name: 'Hulpmiddelen'
        }
    ],
    //levels
    types: [
        {
            id: 1,
            name: 'Basisonderwijs'
        },
        {
            id: 2,
            name: 'Voortgezet onderwijs'
        },
        {
            id: 3,
            name: 'Speciaal onderwijs'
        }
    ]
};

const results = [
    {
        id: 1,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1,2], //not necessary
        types: [1], //not necessary
        //levels: [1]
        //tags: [1,2]
        title: 'Gratis leermethodes voor een startende docent',
        tags: [ //change to categories/tag_names??
            'Basisonderwijs',
            'Methodes',
            'Lesmateriaal'
        ],
        link: '#'
    },
    {
        id: 2,
        author: {
            name: 'Mo Agca',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [2],
        types: [2],
        title: 'Beginnende geletterdheid bij peuters en kleuters; wat doe je ermee?',
        tags: [
            'Voortgezet onderwijs',
            'Lesmateriaal'
        ],
        link: '#'
    },
    {
        id: 3,
        author: {
            name: 'Rindor Golverdingen',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [3,4],
        types: [2],
        title: 'Subiside aanvragen voor leerjaar 2019-2020',
        tags: [
            'Voortgezet onderwijs',
            'NOB Voordeel',
            'Hulpmiddelen'
        ],
        link: '#'
    },
    {
        id: 4,
        author: {
            name: 'Giovanni Marchese',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [1],
        title: 'Kinderen weer leren vallen',
        tags: [
            'Basisonderwijs',
            'Methodes'
        ],
        link: '#'
    },
    {
        id: 5,
        author: {
            name: 'Yira Rodriguez Garcia',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1,2],
        types: [2],
        title: 'Nieuwe lesstof bij Nederlands voor de klas',
        tags: [
            'Voortgezet onderwijs',
            'Methodes',
            'Lesmateriaal'
        ],
        link: '#'
    },
    {
        id: 6,
        author: {
            name: 'Walewijn den Boer',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1,2],
        types: [3],
        title: 'Praktijkvoorbeelden werken met Taalunie',
        tags: [
            'Speciaal onderwijs',
            'Methodes',
            'Lesmateriaal'
        ],
        link: '#'
    },
    {
        id: 7,
        author: {
            name: 'Yira Rodriguez Garcia',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [1,2],
        title: 'Meer tijd voor zelfontplooing op het schoolplein',
        tags: [
            'Basisonderwijs',
            'Voortgezet onderwijs',
            'Methodes'
        ],
        link: '#'
    },
    {
        id: 8,
        author: {
            name: 'Rindor Golverdingen',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [4],
        types: [2],
        title: 'Vroegtijdige schoolverlaters?',
        tags: [
            'Voortgezet onderwijs',
            'Hulpmiddelen'
        ],
        link: '#'
    },
    {
        id: 9,
        author: {
            name: 'Yira Rodriguez Garcia',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [4],
        types: [1,2],
        title: 'Werken aan de nieuwe lesstof voor het Nederlands',
        tags: [
            'Basisonderwijs',
            'Voortgezet onderwijs',
            'Hulpmiddelen'
        ],
        link: '#'
    },
    {
        id: 10,
        author: {
            name: 'Justine de Bruijn',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1,3],
        types: [1],
        title: 'Lorem ipsum est dolor!',
        tags: [
            'Basisonderwijs',
            'Methodes',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 11,
        author: {
            name: 'Rindor Golverdingen',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [3],
        types: [1],
        title: 'Meer voordeel als lid van het NOB',
        tags: [
            'Basisonderwijs',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 12,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [3],
        types: [3],
        title: 'Alle hulpmiddelen voor speciaal onderwijs',
        tags: [
            'Speciaal onderwijs',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 13,
        author: {
            name: 'Michael Danker',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1,3],
        types: [1],
        title: 'Vruchtvlees wint de European Design Awards 2018-2019',
        tags: [
            'Basisonderwijs',
            'Methodes',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 14,
        author: {
            name: 'Michael Danker',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1,3],
        types: [1],
        title: 'Meer onderzoek nodig op leerlingen',
        tags: [
            'Basisonderwijs',
            'Methodes',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 15,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [1],
        title: 'Zorg voor meer tijd voor de klas',
        tags: [
            'Basisonderwijs',
            'Methodes'
        ],
        link: '#'
    },
    {
        id: 16,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [1],
        title: 'Informatie over wijzigingen CAO Docenten',
        tags: [
            'Basisonderwijs',
            'Methodes'
        ],
        link: '#'
    },
    {
        id: 17,
        author: {
            name: 'Michael Danker',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [3],
        title: 'Welke voorzorgsmaatregelen moet je treffen?',
        tags: [
            'Speciaal onderwijs',
            'Methodes'
        ],
        link: '#'
    },
    {
        id: 18,
        author: {
            name: 'Michael Danker',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [3],
        title: 'Hoeveel werk kun je hebben?',
        tags: [
            'Speciaal onderwijs',
            'Methodes'
        ],
        link: '#'
    },
    {
        id: 18,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [1],
        types: [3],
        title: 'Hoeveel kopjes koffie drink jij per dag?',
        tags: [
            'Voortgezet onderwijs',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 19,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [3],
        types: [1],
        title: 'Mag het een onsje meer zijn?',
        tags: [
            'Basisonderwijs',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 20,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [3],
        types: [1],
        title: 'Meer voordeel voor leden van Stichting NOB',
        tags: [
            'Basisonderwijs',
            'NOB Voordeel'
        ],
        link: '#'
    },
    {
        id: 21,
        author: {
            name: 'Sander Hidding',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5a3a2d98cb16433309099952fa2c39dffe40703ca7e20.png'
        },
        themes: [3],
        types: [2],
        title: 'Docenten zeer enthousiast over Stichting NOB',
        tags: [
            'Basisonderwijs',
            'Lesmaterialen'
        ],
        link: '#'
    },
];

const comments = [
    {
        id: 1,
        author: {
            name: 'Sander Hidding',
            role: 'Bestuurder',
            image: 'https://vruchtvlees.ams3.cdn.digitaloceanspaces.com/thumbs/500x500_5bc9aed189f3e6a78a69d568ebd329edcde0912b236a2.png'
        },
        content: 'Hoe neem ik contact op met jullie?',
        time: 1569937001,
        likes: 32
    }
];

export {
    comments,
    resultsMapping,
    results,
    threadMapping,
    threadExample,
    schoolsMapping,
    schools,
};