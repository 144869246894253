import { minus, plus } from '../templates/icons';

/** ----------------------------------------
    Sort Student Data
 ---------------------------------------- */

export class SortStudentData {

    constructor() {
        this.form = document.getElementById('js-sort-student-data');

        if(this.form == null)
            return;

        this.previewData = JSON.parse(this.form.dataset.preview);
        this.groupElems = this.form.querySelectorAll('.form__group');
        this.groups = Array.from(this.groupElems).map(groupEl => {
            return {
                groupEl: groupEl,
                selectEl: groupEl.querySelector('input'),
                previewEl: groupEl.querySelector('.funds__preview'),
                previewShowEl: groupEl.querySelector('.funds__preview-show'),
                noMatchEl: groupEl.querySelector('.funds__no-match'),
            };
        });

        this.init();
    }

    setEvents() {
        this.groups.forEach(group => {
            group.selectEl.addEventListener('change', ev => {
                this.updatePreview(group);

                if (group.noMatchEl)
                    group.noMatchEl.remove();
            });
        });
    }

    updatePreview(group) {
        const value = group.selectEl.value;
        const data = this.previewData[value];

        if (data && data.length) {
            let html = '<span>Voorvertoning:</span><div class="funds__preview-table">';

            data.forEach(row => {
                html+= `<span>${row}</span>`;
            });

            html+= '<span>...</span></div>';

            group.previewEl.innerHTML = html;
        } else {
            group.previewEl.innerHTML = '<span>Geen data beschikbaar</span>';
        }
    }

    init() {
        this.setEvents();

        this.groups.forEach(group => {
            this.updatePreview(group);
        });
    }
}
