/* ----------------------------------------
    Node Modules
---------------------------------------- */

import axios from 'axios';

import { validateFormFiles } from './../helpers/Helpers.js';

/* ----------------------------------------
    AddThread modal handling
---------------------------------------- */

export class AddThread {

    constructor() {
        this._form = document.getElementById('new-thread-form');

        if (!this._form) 
            return;

        this._fields = ['title', 'description', 'theme'];

        this._init();
    }

    _formValues() {
        let themeSelect = this._form.querySelector('[name="theme"]');
        let theme = themeSelect.value;

        return {
            title: this._form.querySelector('#new-thread-form-title').value,
            description: this._form.querySelector('#new-thread-form-description').value,
            theme: theme,
        };
    }

    _handleErrors(data) {
        let errorKeys = Object.keys(data.errors);

        for(let i = 0; i < this._fields.length; i++) {
            if(errorKeys.includes(this._fields[i])) {
                this._form.querySelector(`#new-thread-form-${this._fields[i]}`).classList.add('is-invalid');
                this._form.querySelector(`#new-thread-form-${this._fields[i]}-error`).textContent = data.errors[this._fields[i]];
            }
        }
    }

    _removeErrorState() {
        for(let i = 0; i < this._fields.length; i++) {
            this._form.querySelector(`#new-thread-form-${this._fields[i]}`).classList.remove('is-invalid');
            this._form.querySelector(`#new-thread-form-${this._fields[i]}-error`).textContent = '';
        }
    }

    _events() {
        this._form.addEventListener('submit', e => {
            e.preventDefault();

            this._removeErrorState();

            let data = this._formValues();

            if (validateFormFiles(this._form)) {
                axios.post('/threads', data)
                    .then(response => {
                        if(response.status === 200)
                            this._form.submit();
                    })
                    .catch(({ response }) => {
                        if (response) {
                            this._handleErrors(response.data);
                        }
                    });
            }
        });
    }

    _init() {
        this._events();
    }
}
