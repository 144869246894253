<template>
    <div class="form__group">
        <div class="select">
            <a href="javascript:;" class="select__trigger">
                <span class="select__trigger-text">
                    {{ filter.name }}&nbsp;
                </span>
                <Icon></Icon>
            </a>
            <select :name="name" v-model="selected" v-on:change="onFilter(filter.label)">
                <option value="">{{ filter.name }}</option>
                <option :value="0">Selecteer alles</option>
                <option v-for="(option, key) in options" :key="key" :value="option.id">{{ option.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>

    /* ----------------------------------------
        Icons
    ---------------------------------------- */

    import Icon from '@js/vue/svg/arrow-down.vue';

    /* ----------------------------------------
        Component
    ---------------------------------------- */

    /**
     * This component uses the Dropdown module
     * as included within the VV Boilerplate.
     * Several functionalities are therefor
     * already embedded from reading the DOM.
     */

    export default {

        /* ----------------------------------------
            Data
        ---------------------------------------- */

        /**
         * Stores the selected filter from the
         * v-model appended on the radio input.
         */

        data() {
            return {
                selected: []
            };
        },

        /* ----------------------------------------
            Props
        ---------------------------------------- */

        /**
         * Will receive a filter object that contains
         * both a name and a label for configuration.
         * The name will be displayed by default and
         * the label contains the key value that can
         * be mapped on the items.
         */

        props: ['filter', 'options'],
        
        components: {
            Icon
        },

        computed: {

            /* ----------------------------------------
                Name
            ---------------------------------------- */

            /**
             * This function will convert the filter name
             * to kebab case for preventing conflicting
             * selections once multiple picklists have been
             * declared on the same page.
             */

            name() {
                const name = this.filter.name.toLowerCase();
                return name.replace(/\s+/g, '-');
            }
        },

        methods: {

            /* ----------------------------------------
                Id
            ---------------------------------------- */

            /**
             * This function will set an id on a given
             * input so that the id will be unique given
             * to any dropdown set within the page.
             */

            id(id) {
                return this.name + '-' + id;
            },

            /* ----------------------------------------
                Filter
            ---------------------------------------- */

            /**
             * Once the user selects a filter the onFilter
             * method will dispatch an event with the
             * selected filter values. This event can be
             * intercepted using the @filtered event.
             */

            onFilter(filter) {
                this.$emit('filtered', {
                    type: filter,
                    value: this.selected
                })
            }

        }
    };

</script>